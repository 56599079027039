@if(largeView){
<!-- DESKTOP VIEW -->
<div class="mt-1">
  <div class="roster-functions">
    <div class="function-child">
      <button
        (click)="previousWeek()"
        class="btn btn-lg btn-secondary w-100"
        title="previous"
        data-bs-toggle="tooltip"
        data-bs-placement="top"
      >
        <span>&lt;</span>
      </button>
    </div>

    <div class="function-child">
      <div class="form-floating">
        <input
          type="date"
          id="dateTimePickerDesk"
          class="form-control"
          placeholder="Selected Date"
          [(ngModel)]="largeScreenDateSelected"
          (change)="changeSelectedDate($event)"
        />
        <label for="dateTimePickerDesk">Selected Date</label>
      </div>
    </div>

    <div class="function-child">
      <button
        (click)="nextWeek()"
        class="btn btn-lg btn-secondary w-100"
        title="next"
        data-bs-toggle="tooltip"
        data-bs-placement="top"
      >
        <span>&gt;</span>
      </button>
    </div>
    <div class="function-child">
      <button
        (click)="openSearchTemplate(searchTemplateRef)"
        class="btn btn-lg btn-secondary w-100"
        data-bs-toggle="tooltip"
        data-bs-placement="top"
      >
        Search Jobs
      </button>
    </div>

    <div class="function-child" id="btn-conflicts">
      <button
        *ngIf="conflictData?.length && (isAdmin || isDispatchUser)"
        (click)="openConflictTemplate(conflictTemplate)"
        class="btn btn-lg btn-danger w-100"
      >
        Conflicts
      </button>
    </div>

    <div class="function-child">
      <div class="slider-section">
        <div class="fw-bold">Show {{ desktopDayDuration + 1 }} Days</div>
        <input
          class="range-slider-input"
          type="range"
          min="3"
          max="6"
          step="1"
          pattern="\d*"
          title="daysToShow"
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          [value]="desktopDayDuration"
          (mouseup)="updateWeekNumber($event)"
          (touchend)="updateWeekNumber($event)"
        />
      </div>
    </div>
  </div>

  <div class="roster-table">
    <table>
      <thead>
        <tr>
          <th *ngFor="let date of weekDays" (click)="addStaticSchedule(date)">
            <div class="weekday">
              <div>
                <div>
                  {{ date | date : "yyyy-MM-dd" }}
                </div>
                <div>
                  {{ date | misc : "getWeekDay" }}
                </div>
              </div>
              <div *ngIf="isAdmin || isDispatchUser" class="addJob">+</div>
            </div>
          </th>
        </tr>
      </thead>
      <tbody *ngIf="groupByData?.length">
        <tr *ngFor="let grpEvent of groupByData">
          <ng-container *ngIf="grpEvent?.event_data?.length">
            <td *ngFor="let date of weekDays">
              <div
                class="client-name fw-bold"
                *ngIf="hasEventOnDay(date, grpEvent)"
              >
                {{ grpEvent?.detail?.company_name }}
              </div>

              <div *ngFor="let schedule of grpEvent?.event_data">
                <div
                  class="scheduled-item"
                  (click)="
                    openEventDetailPage(schedule, date, choiceTemplateRef);
                    $event.stopImmediatePropagation()
                  "
                  *ngIf="
                    schedule?.event_dates?.includes(
                      date | date : 'yyyy-MM-dd'
                    ) &&
                    (isAdmin ||
                      isDispatchUser ||
                      isGuardPresentCheck(schedule, date)) &&
                    (schedule?.repeat_type !== 'once' ||
                      (schedule?.repeat_type == 'once' &&
                        (schedule?.start_day | date : 'yyyy-MM-dd') ===
                          (date | date : 'yyyy-MM-dd')))
                  "
                >
                  <div
                    class="conflict-detected"
                    *ngIf="isScheduleInConflict(date, schedule)"
                  >
                    Conflict Detected
                  </div>

                  <div
                    *ngIf="
                      schedule
                        | misc
                          : 'trashIconIf'
                          : date
                          : isAdmin || isDispatchUser
                    "
                  >
                    <span
                      class="float-end"
                      (click)="
                        openTemplate(deleteScheduleTemplate, schedule, date);
                        $event.stopImmediatePropagation()
                      "
                      ><i class="fa-regular fa-trash-can fa-2x"></i
                    ></span>
                  </div>
                  <div
                    class="row"
                    [ngClass]="
                      isAdmin || isDispatchUser ? 'row-cols-2' : 'row-cols-1'
                    "
                  >
                    <div
                      class="col-4"
                      *ngIf="isAdmin || isDispatchUser"
                      style="
                        display: flex;
                        flex-direction: column-reverse;
                        justify-content: space-between;
                      "
                    >
                      <div class="event-type">
                        {{
                          schedule?.patrol_route_details
                            ? (schedule
                              | misc : "getPatrolCheckpointName" : date)
                            : (schedule?.job_details?.job_type | titlecase)
                        }}
                      </div>
                      @if( ( schedule | misc : 'getEventAssignees' : date :
                      isAdmin || isDispatchUser )?.length){
                      <div class="assignee-list">
                        <div>
                          <span>
                            {{
                              (schedule
                                | misc
                                  : "getEventAssignees"
                                  : date
                                  : isAdmin || isDispatchUser)[0]?.full_name
                            }}
                          </span>
                        </div>
                        <button
                          class="btn btn-outline-secondary btn-sm"
                          *ngIf="
                            (
                              schedule
                              | misc
                                : 'getEventAssignees'
                                : date
                                : isAdmin || isDispatchUser
                            )?.length > 1 && !schedule?.showAllAssignees
                          "
                          (click)="
                            schedule.showAllAssignees = true;
                            $event.stopImmediatePropagation()
                          "
                        >
                          {{
                            (
                              schedule
                              | misc
                                : "getEventAssignees"
                                : date
                                : isAdmin || isDispatchUser
                            )?.length - 1
                          }}
                          more
                        </button>
                        <div *ngIf="schedule?.showAllAssignees">
                          <div
                            *ngFor="
                              let assignee of (
                                schedule
                                | misc
                                  : 'getEventAssignees'
                                  : date
                                  : isAdmin || isDispatchUser
                              ).slice(1)
                            "
                          >
                            <span>
                              {{ assignee?.full_name }}
                            </span>
                          </div>
                        </div>
                      </div>
                      } @else {
                      <div class="text-danger fw-bold">Unassigned</div>
                      }
                    </div>

                    <div
                      *ngIf="isAdmin || isDispatchUser ? 'col-8 ' : 'col-12'"
                    >
                      <div
                        *ngIf="schedule?.job_details?.job_type"
                        [ngStyle]="cardStyling?.status?.[schedule|misc:'getJobStatus':date]"
                      >
                        {{ schedule | misc : "getJobStatus" : date }}
                      </div>
                      <div *ngIf="!(isAdmin || isDispatchUser)" class="fw-bold">
                        {{
                          schedule?.patrol_route_details
                            ? "Patrol"
                            : (schedule?.job_details?.job_type | titlecase)
                        }}
                      </div>
                      <div class="fw-bold">
                        {{ schedule?.start_time }} -
                        {{ schedule?.end_time }}
                      </div>

                      <div>
                        {{ schedule | misc : "getTimeDifference" }}
                        Hours
                      </div>

                      <div *ngIf="schedule?.job_details?.job_type">
                        <!--job html code-->

                        <div class="fw-bold">
                          {{ schedule?.job_details?.site_name }}
                        </div>
                      </div>
                      <div *ngIf="schedule?.patrol_route_details">
                        <!--patrol route html code-->
                        <div *ngIf="!(isAdmin || isDispatchUser)">
                          {{
                            schedule | misc : "getPatrolCheckpointName" : date
                          }}
                        </div>

                        <div>
                          <i class="fa-solid fa-location-dot"></i>
                          {{
                            schedule | misc : "getPatrolCheckpointCount" : date
                          }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </td>
          </ng-container>
        </tr>
      </tbody>
    </table>
  </div>
</div>

} @else{
<!--mobile view-->
<div class="d-flex text-center my-1">
  <div class="w-25 p-1">
    <div
      (click)="previousWeek()"
      data-bs-toggle="tooltip"
      data-bs-placement="top"
      class="btn btn-secondary w-100"
    >
      <i class="fas fa-arrow-left"></i>
    </div>
  </div>
  <div class="w-50 p-1">
    <ng-container *ngIf="mobileselectedDate">
      <input
        type="date"
        id="dateTimePicker"
        class="d-none"
        [(ngModel)]="mobileselectedDate"
        (change)="changeSelectedDate($event)"
      />

      <div (click)="openDatePicker()" class="btn btn-primary w-100">
        {{ mobileselectedDate | date : "d MMM" }}
        <span class="float-end">
          <i class="fa regular fa-calendar"></i>
        </span>
      </div>
    </ng-container>
  </div>
  <div class="w-25 p-1">
    <div
      data-bs-toggle="tooltip"
      data-bs-placement="top"
      (click)="nextWeek()"
      class="btn btn-secondary w-100"
    >
      <i class="fas fa-arrow-right"></i>
    </div>
  </div>
</div>

<div class="d-flex">
  <div
    *ngFor="let date of weekDays"
    class="mobile-calendar"
    [ngStyle]="
      (date | date : 'yyyy-MM-dd') ===
      (mobileselectedDate | date : 'yyyy-MM-dd')
        ? {
            'background-color': 'var(--bs-primary)'
          }
        : ['Su', 'Sa'].includes(date | misc : 'getWeekDay')
        ? {
            'font-weight': '700'
          }
        : {}
    "
    (click)="formatMobileRosterSchedules(date)"
  >
    <div>
      {{ date | misc : "getWeekDay" }}
    </div>
    <div>
      {{ date | date : "dd" }}
    </div>
    <div>
      {{ date | date : "MMM" }}
    </div>
  </div>
</div>

<ng-container *ngIf="conflictData?.length && (isAdmin || isDispatchUser)">
  <div
    class="text-center mt-1"
    (click)="openConflictTemplate(conflictTemplate)"
  >
    <button class="btn btn-danger w-100 btn-sm">Conflicts</button>
  </div>
</ng-container>

<div class="mt-2">
  <!--data section-->
  <div *ngFor="let schedule of mobileRosterScheduleData">
    <div
      (click)="
        openEventDetailPage(schedule, mobileselectedDate, choiceTemplateRef)
      "
      class="scheduled-item"
    >
      <div
        class="row"
        [ngClass]="isAdmin || isDispatchUser ? 'row-cols-2' : 'row-cols-1'"
      >
        <div
          class="col-4"
          *ngIf="isAdmin || isDispatchUser"
          style="
            display: flex;
            flex-direction: column-reverse;
            justify-content: space-between;
          "
        >
          <div class="event-type">
            {{
              schedule?.patrol_route_details
                ? "Patrol"
                : (schedule?.job_details?.job_type | titlecase)
            }}
          </div>
          @if( ( schedule | misc : 'getEventAssignees' : mobileselectedDate :
          isAdmin || isDispatchUser )?.length){
          <div class="assignee-list">
            <div>
              <span
                [ngClass]="
                  isGuardInConflict(
                    mobileselectedDate,
                    (schedule
                      | misc
                        : 'getEventAssignees'
                        : mobileselectedDate
                        : isAdmin || isDispatchUser)[0]?.id,
                    schedule
                  )
                    ? 'conflictGuard'
                    : ''
                "
              >
                {{
                  (schedule
                    | misc
                      : "getEventAssignees"
                      : mobileselectedDate
                      : isAdmin || isDispatchUser)[0]?.full_name
                }}
              </span>
            </div>
            <button
              class="btn btn-outline-secondary btn-sm"
              *ngIf="
                (
                  schedule
                  | misc
                    : 'getEventAssignees'
                    : mobileselectedDate
                    : isAdmin || isDispatchUser
                )?.length > 1 && !schedule?.showAllAssignees
              "
              (click)="
                schedule.showAllAssignees = true;
                $event.stopImmediatePropagation()
              "
            >
              {{
                (
                  schedule
                  | misc
                    : "getEventAssignees"
                    : mobileselectedDate
                    : isAdmin || isDispatchUser
                )?.length - 1
              }}
              more
            </button>
            <div *ngIf="schedule?.showAllAssignees">
              <div
                *ngFor="
                  let assignee of (
                    schedule
                    | misc
                      : 'getEventAssignees'
                      : mobileselectedDate
                      : isAdmin || isDispatchUser
                  ).slice(1)
                "
              >
                <span
                  [ngClass]="
                    isGuardInConflict(
                      mobileselectedDate,
                      assignee?.id,
                      schedule
                    )
                      ? 'conflictGuard'
                      : ''
                  "
                >
                  {{ assignee?.full_name }}
                </span>
              </div>
            </div>
          </div>
          } @else{
          <div class="text-danger fw-bold">Unassigned</div>
          }
        </div>
        <div [ngClass]="isAdmin || isDispatchUser ? 'col-8 ' : 'col-12'">
          <span
            class="float-end"
            *ngIf="
              schedule
                | misc
                  : 'trashIconIf'
                  : mobileselectedDate
                  : isAdmin || isDispatchUser
            "
            (click)="
              openTemplate(
                deleteScheduleTemplate,
                schedule,
                mobileselectedDate
              );
              $event.stopImmediatePropagation()
            "
            title="Delete"
          >
            <i class="fa-regular fa-trash-can fa-2x"></i>
          </span>

          <span
            *ngIf="schedule?.job_details?.job_type"
            [ngStyle]="cardStyling?.status?.[schedule|misc:'getJobStatus':mobileselectedDate]"
            [ngClass]="!(isAdmin || isDispatchUser) ? 'float-end' : ''"
          >
            {{ schedule | misc : "getJobStatus" : mobileselectedDate }}
          </span>
          <div *ngIf="!(isAdmin || isDispatchUser)" class="fw-bold">
            {{
              schedule?.patrol_route_details
                ? "Patrol"
                : (schedule?.job_details?.job_type | titlecase)
            }}
          </div>
          <div class="fw-bold">
            {{ schedule?.start_time }} - {{ schedule?.end_time }}
          </div>

          <div>
            {{ schedule | misc : "getTimeDifference" }}
            Hours
          </div>

          <div *ngIf="schedule?.job_details?.job_type">
            <!--job html code-->

            <div>
              {{ schedule?.job_details?.site_name }}
            </div>
            <div>
              {{ schedule?.job_details?.company_name }}
            </div>
          </div>

          <div *ngIf="schedule?.patrol_route_details">
            <!--patrol route html code-->
            <div>
              {{
                schedule | misc : "getPatrolCheckpointName" : mobileselectedDate
              }}
            </div>

            <div>
              <i class="fa-solid fa-location-dot"></i>
              {{
                schedule
                  | misc : "getPatrolCheckpointCount" : mobileselectedDate
              }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- NO job present for the date-->
  @if(!mobileRosterScheduleData?.length && mobileselectedDate){

  <div class="centered">
    <div class="alert alert-secondary">
      No Jobs rostered for {{ mobileselectedDate | customDate : "d MMM y" }}
    </div>
  </div>
  }
</div>

<div class="mt-1 mx-1" *ngIf="isAdmin || isDispatchUser">
  <div class="fixed-add-btn">
    <button
      class="btn btn-lg btn-secondary"
      (click)="openSearchTemplate(searchTemplateRef)"
    >
      <span> <i class="fa-solid fa-magnifying-glass-plus"></i> </span>
    </button>
    <button
      class="btn btn-lg btn-primary"
      (click)="addStaticSchedule(mobileselectedDate)"
    >
      <span><i class="fa-solid fa-plus"></i></span>
    </button>
  </div>
</div>
}

<ng-template
  #deleteScheduleTemplate
  let-deleteScheduleTemplate="dialogRef"
  let-data
>
  <div class="dialog-box card">
    <div class="card-header">
      Delete
      <span class="float-end" (click)="deleteScheduleTemplate.close()">
        <i class="fa-solid fa-xmark"></i>
      </span>
    </div>
    <div>
      <div class="mt-3" *ngIf="data?.repeat_type !== 'once'">
        <label class="form-label">Select an Option:</label>
        <div class="form-group row row-cols-1 row-cols-lg-2 ms-2">
          <!-- Radio Group Label, if needed -->

          <div class="form-check">
            <input
              class="form-check-input"
              type="radio"
              name="deleteOption"
              id="deleteOption1"
              value="1"
              [(ngModel)]="deleteOptionSelected"
              [checked]="deleteOptionSelected === 1"
            />
            <label class="form-check-label" for="deleteOption1">
              This
              {{
                data?.job_details?.job_type
                  ? data?.job_details?.job_type + " job"
                  : "patrol"
              }}
            </label>
          </div>

          <div class="form-check">
            <input
              class="form-check-input"
              type="radio"
              name="deleteOption"
              id="deleteOption2"
              value="2"
              [(ngModel)]="deleteOptionSelected"
              [checked]="deleteOptionSelected === 2"
            />
            <label class="form-check-label" for="deleteOption2">
              This and all future occurrences
            </label>
          </div>
        </div>
      </div>
      <div class="mt-3" *ngIf="data?.repeat_type == 'once'">
        <div class="alert alert-danger">
          This
          {{
            data?.job_details?.job_type
              ? data?.job_details?.job_type + " job"
              : "patrol"
          }}
        </div>
      </div>
    </div>
    <div class="mt-3">
      <button
        class="btn btn-lg btn-danger w-100"
        (click)="deleteScheduleEvents(data); deleteScheduleTemplate.close()"
      >
        Delete
      </button>
    </div>
  </div>
</ng-template>

<ng-template #conflictTemplate let-conflictTemplate="dialogRef">
  <div class="dialog-box card">
    <div class="card-header">
      Conflicts
      <span class="float-end" (click)="conflictTemplate.close()">
        <i class="fa-solid fa-xmark"></i>
      </span>
    </div>
    <div *ngFor="let conflictItem of conflictData">
      <div class="text-center fs-4 fw-bold">
        {{ conflictItem?.eventDate | customDate : "d MMM y" }}
      </div>
      <div *ngFor="let conflict of conflictItem?.conflictInfo | keyvalue">
        <div class="ps-1 mb-1 fw-bold">
          {{ getConflictAssigneeInfo(conflict?.value)?.full_name }}
        </div>
        <div class="row row-cols-1 row-cols-lg-2">
          <div
            *ngFor="
              let conflictSchedule of conflict?.value
                | misc : 'getConflictSchedules'
            "
          >
            <div
              (click)="
                openEventDetailPage(
                  conflictSchedule,
                  conflictItem?.eventDate,
                  choiceTemplateRef
                );
                conflictTemplate.close()
              "
              class="conflict-item border-2 border-dark"
              *ngIf="
                !conflictSchedule?.exception_dates?.includes(
                  conflictItem?.eventDate | date : 'yyyy-MM-dd'
                )
              "
            >
              <div class="float-end">
                {{ conflictSchedule | misc : "getTimeDifference" }}
                Hours
              </div>
              <div>
                {{
                  conflictSchedule?.patrol_route_details
                    ? "Patrol"
                    : (conflictSchedule?.job_details?.job_type | titlecase)
                }}
              </div>

              <div class="fw-bold text-danger">
                {{ conflictSchedule?.start_time }} -
                {{ conflictSchedule?.end_time }}
              </div>

              <div *ngIf="conflictSchedule?.job_details?.job_type">
                <!--job html code-->

                <span
                  class="float-end"
                  [ngStyle]="cardStyling?.status?.[conflictSchedule |misc:'getJobStatus':conflictItem?.eventDate]"
                >
                  {{
                    conflictSchedule
                      | misc : "getJobStatus" : conflictItem?.eventDate
                  }}
                </span>
                <div class="fw-bold">
                  {{ conflictSchedule?.job_details?.site_name }}
                </div>
                <div>
                  {{ conflictSchedule?.job_details?.company_name }}
                </div>
              </div>

              <div *ngIf="conflictSchedule?.patrol_route_details">
                <!--patrol route html code-->
                <div class="fw-bold">
                  {{
                    conflictSchedule
                      | misc
                        : "getPatrolCheckpointName"
                        : conflictItem?.eventDate
                  }}
                </div>

                <div>
                  <i class="fa-solid fa-location-dot"></i>
                  {{
                    conflictSchedule
                      | misc
                        : "getPatrolCheckpointCount"
                        : conflictItem?.eventDate
                  }}
                </div>
              </div>
              <div class="mt-2 d-flex mx-auto" *ngIf="conflictItem?.eventDate">
                <button
                  class="btn btn-primary w-100"
                  (click)="
                    reassigneGuards(
                      modifyAssigneeRef,
                      conflictSchedule,
                      conflictItem?.eventDate,
                      getConflictAssigneeInfo(conflict?.value)
                    );
                    $event.stopImmediatePropagation();
                    conflictTemplate.close()
                  "
                >
                  Assign to another guard
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<!-- Dialogue for assign users -->
<ng-template #modifyAssigneeRef let-data let-modifyAssigneeRef="dialogRef">
  <div class="dialog-box card">
    <div class="text-center">
      Assigned Guards
      <span class="float-end" (click)="modifyAssigneeRef.close()">
        <i class="fa-solid fa-xmark"></i>
      </span>
    </div>
    <div>
      <gtapp-auto-complete
        [data]="guardsList"
        placeHolder="Search/Select Assignee"
        searchKeyword="full_name"
        [initialValue]="assineeNameValue"
        [itemTemplate]="assigneeTemplate"
        (inputChanged)="getAssignees($event, data)"
        (selected)="onAssigneeSelect($event, data)"
      >
      </gtapp-auto-complete>
      <ng-template #assigneeTemplate let-guard>
        <div>
          {{ guard?.full_name ? guard.full_name : guard.email }}

          <span [attr.aria-label]="'User with a valid state license'">
            <i class="fa-solid fa-check-double"></i>
          </span>
        </div>
      </ng-template>
    </div>
  </div>
</ng-template>

<ng-template #choiceTemplateRef let-data let-choiceTemplateRef="dialogRef">
  <div class="dialog-box card">
    <div class="card-header">
      Choose
      <span class="float-end" (click)="choiceTemplateRef.close()">
        <i class="fa-solid fa-xmark"></i>
      </span>
    </div>
    <div>
      <div class="row row-cols-1 row-cols-lg-2 gy-2 mt-2">
        <div
          class="col"
          (click)="
            data?.schedule?.job_details?.job_type
              ? openJobDetailPage(data?.schedule, data?.date)
              : openPatrolRoutePage(data?.schedule, data?.date);
            choiceTemplateRef.close()
          "
        >
          <button class="btn btn-lg btn-secondary w-100">
            Open this
            {{
              data?.schedule?.job_details?.job_type
                ? data?.schedule?.job_details?.job_type
                : ("Patrol" | titlecase)
            }}
            job
          </button>
        </div>
        <div
          class="col"
          (click)="
            choiceTemplateRef.close(); editSchedule(data?.schedule, data?.date)
          "
        >
          <button class="btn btn-lg btn-secondary w-100">Edit Schedule</button>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #searchTemplateRef let-data let-searchTemplateRef="dialogRef">
  <div class="dialog-box card">
    <div class="card-header">
      Search Jobs
      <span class="float-end" (click)="searchTemplateRef.close()">
        <i class="fa-solid fa-xmark"></i>
      </span>
    </div>
    <div>
      <div class="mt-1 form-floating">
        <input
          class="form-control input-font-sm"
          placeholder="Pick Date Range"
          (click)="openRangePicker()"
          [value]="dateRangeValue"
        />
        <label for="date_range">Pick Date Range</label>
      </div>
      <div class="form-floating my-3" *ngIf="searchDateRange">
        <input
          type="text"
          class="form-control"
          id="filterJobsInput"
          placeholder="Filter Jobs"
          (keyup)="filterJobs($event)"
        />
        <label for="filterJobsInput">Filter Jobs</label>
      </div>

      <div *ngIf="!searchResults?.length" class="my-3">
        <div role="alert" class="alert alert-secondary">
          No Records Match Your Search Criteria
        </div>
      </div>

      <div *ngFor="let searchItem of searchResults">
        <h4 class="text-center mt-3">
          {{ searchItem?.eventDate | customDate : "d MMM y" }}
        </h4>
        <div *ngFor="let schedule of searchItem?.dayJobs">
          <div
            (click)="
              openEventDetailPage(
                schedule,
                searchItem?.eventDate,
                choiceTemplateRef
              );
              searchTemplateRef.close()
            "
            class="scheduled-item"
          >
            <div
              class="row"
              [ngClass]="
                isAdmin || isDispatchUser ? 'row-cols-2' : 'row-cols-1'
              "
            >
              <div
                class="col-4"
                *ngIf="isAdmin || isDispatchUser"
                style="
                  display: flex;
                  flex-direction: column-reverse;
                  justify-content: space-between;
                "
              >
                <div class="event-type">
                  {{
                    schedule?.patrol_route_details
                      ? "Patrol"
                      : (schedule?.job_details?.job_type | titlecase)
                  }}
                </div>
                <div
                  class="assignee-list"
                  *ngIf="
                    (
                      schedule
                      | misc
                        : 'getEventAssignees'
                        : searchItem?.eventDate
                        : isAdmin || isDispatchUser
                    )?.length
                  "
                >
                  <div>
                    <span
                      [ngClass]="
                        isGuardInConflict(
                          searchItem?.eventDate,
                          (schedule
                            | misc
                              : 'getEventAssignees'
                              : searchItem?.eventDate
                              : isAdmin || isDispatchUser)[0]?.id,
                          schedule
                        )
                          ? 'conflictGuard'
                          : ''
                      "
                    >
                      {{
                        (schedule
                          | misc
                            : "getEventAssignees"
                            : searchItem?.eventDate
                            : isAdmin || isDispatchUser)[0]?.full_name
                      }}
                    </span>
                  </div>
                  <button
                    class="btn btn-outline-secondary btn-sm"
                    *ngIf="
                      (
                        schedule
                        | misc
                          : 'getEventAssignees'
                          : searchItem?.eventDate
                          : isAdmin || isDispatchUser
                      )?.length > 1 && !schedule?.showAllAssignees
                    "
                    (click)="
                      schedule.showAllAssignees = true;
                      $event.stopImmediatePropagation()
                    "
                  >
                    {{
                      (
                        schedule
                        | misc
                          : "getEventAssignees"
                          : searchItem?.eventDate
                          : isAdmin || isDispatchUser
                      )?.length - 1
                    }}
                    more
                  </button>
                  <div *ngIf="schedule?.showAllAssignees">
                    <div
                      *ngFor="
                        let assignee of (
                          schedule
                          | misc
                            : 'getEventAssignees'
                            : searchItem?.eventDate
                            : isAdmin || isDispatchUser
                        ).slice(1)
                      "
                    >
                      <span
                        [ngClass]="
                          isGuardInConflict(
                            searchItem?.eventDate,
                            assignee?.id,
                            schedule
                          )
                            ? 'conflictGuard'
                            : ''
                        "
                      >
                        {{ assignee?.full_name }}
                      </span>
                    </div>
                  </div>
                </div>
                <div
                  *ngIf="
                    !(
                      schedule
                      | misc
                        : 'getEventAssignees'
                        : searchItem?.eventDate
                        : isAdmin || isDispatchUser
                    )?.length &&
                    (isAdmin || isDispatchUser)
                  "
                  class="danger-text-info fw-bold"
                >
                  Unassigned
                </div>
              </div>
              <div [ngClass]="isAdmin || isDispatchUser ? 'col-8 ' : 'col-12'">
                <span
                  *ngIf="schedule?.job_details?.job_type"
                  [ngStyle]="cardStyling?.status?.[schedule |misc:'getJobStatus':searchItem?.eventDate]"
                  [ngClass]="!(isAdmin || isDispatchUser) ? 'float-end' : ''"
                >
                  {{ schedule | misc : "getJobStatus" : searchItem?.eventDate }}
                </span>
                <div *ngIf="!(isAdmin || isDispatchUser)" class="fw-bold">
                  {{
                    schedule?.patrol_route_details
                      ? "Patrol"
                      : (schedule?.job_details?.job_type | titlecase)
                  }}
                </div>
                <div class="fw-bold">
                  {{ schedule?.start_time }} - {{ schedule?.end_time }}
                </div>

                <div>
                  {{ schedule | misc : "getTimeDifference" }}
                  Hours
                </div>

                <div *ngIf="schedule?.job_details?.job_type">
                  <!--job html code-->

                  <div class="fw-bold">
                    {{ schedule?.job_details?.site_name }}
                  </div>
                  <div>
                    {{ schedule?.job_details?.company_name }}
                  </div>
                </div>

                <div *ngIf="schedule?.patrol_route_details">
                  <!--patrol route html code-->
                  <div>
                    {{
                      schedule
                        | misc
                          : "getPatrolCheckpointName"
                          : searchItem?.eventDate
                    }}
                  </div>

                  <div>
                    <i class="fa-solid fa-location-dot"></i>
                    {{
                      schedule
                        | misc
                          : "getPatrolCheckpointCount"
                          : searchItem?.eventDate
                    }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
