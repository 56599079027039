<div id="app-container">
  <nav
    (click)="lockScreen()"
    class="navbar navbar-expand-lg custom-sticky-header"
    [ngClass]="currentTheme === 'dark' ? '' : 'bg-body-tertiary'"
  >
    <div class="container-fluid px-1">
      <div
        id="sidebar-menu"
        *ngIf="
          isAppOnline &&
          isSubscriptionActive &&
          (isAdmin || (isOnlyGuardAndDispatcher && !showWelfareCheckButton))
        "
        class="navbar-toggler border-0"
        (click)="toggleSideBarMenu()"
      >
        <i class="fa-solid fa-bars"></i>
      </div>

      <!-- Logo -->

      <div
        id="gt-logo-home"
        (click)="
          handleWrapperClick($event); navigateHome(); navbarToggled = false
        "
      >
        <img
          src="../../../assets/images/Guard_Tracker-Lateral_Long_{{
            currentTheme
          }}.avif"
          alt="Guard Tracker"
        />
      </div>
      <div *ngIf="isSubscriptionActive" class="d-md-none">
        <!-- status icon to show whether there is background sync by service worker and user offline/online status-->

        <div class="d-flex status-indicator small">
          <div
            id="customTooltipBtn"
            class="custom-tooltip"
            data-bs-toggle="tooltip"
            data-bs-placement="bottom"
            [attr.data-bs-original-title]="
              permissionErrorStatus?.message
                ? permissionErrorStatus?.message
                : isInBackgroundSync
                ? 'Synchronizing Data'
                : isAppOnline
                ? 'Online'
                : 'Offline: Events will be uploaded once you are back online'
            "
            [gtappCustomeTooltip]="permissionErrorStatus?.status"
          >
            <span style="vertical-align: top"> Status </span>

            <span *ngIf="permissionErrorStatus?.icon === 'alert-triangle'">
              <i class="fa fa-exclamation-triangle text-danger"></i>
            </span>
            <span *ngIf="!permissionErrorStatus?.icon && isInBackgroundSync">
              <i class="fa fa-exclamation-triangle text-warning"></i>
            </span>
            <span
              *ngIf="
                !permissionErrorStatus?.icon &&
                !isInBackgroundSync &&
                isAppOnline
              "
            >
              <i class="fa fa-check-circle text-success"></i>
            </span>

            <span *ngIf="!permissionErrorStatus?.icon && !isAppOnline">
              <span *ngIf="!internetConnectionAvailable">
                <i class="fa fa-wifi text-danger"></i
              ></span>
              <span *ngIf="internetConnectionAvailable">
                <i class="fa fa-wifi text-warning"></i
              ></span>
            </span>
          </div>

          <!-- <div
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="{{
              isInBackgroundSync
                ? 'Synchronizing Data'
                : isAppOnline
                ? 'Online'
                : 'Offline: Events will be uploaded once you are back online'
            }}"
          >
            <span class="align-top"> Status </span>
            <span *ngIf="isInBackgroundSync"
              ><i class="fa fa-exclamation-triangle text-warning"></i>
            </span>
            <span *ngIf="!isInBackgroundSync && isAppOnline">
              <i class="fa fa-check-circle text-success"></i>
            </span>
            <span *ngIf="!isInBackgroundSync && !isAppOnline"
              ><i class="fa fa-wifi text-danger"></i
            ></span>
          </div> -->
        </div>
      </div>
      <!-- Notification Icon -->
      <div class="ms-auto position-relative" *ngIf="isAppOnline">
        <span *ngIf="notificationCount > 0">
          <div
            id="notifications"
            (click)="openNotifcationTemplate(notificationPopover)"
          >
            <i
              class="fa-regular fa-bell fa-2x"
              [class.blink]="sosAlertCount && (isAdmin || isDispatchUser)"
            ></i>
            <span class="badge bg-danger position-absolute top-0 end-0">{{
              notificationCount
            }}</span>
          </div>
        </span>
      </div>
      <!-- Feedback Icon -->
      <div
        *ngIf="isAppOnline && isPreviousSubscribed"
        id="globalFeedbackbtn"
        (click)="capture('draggableBtn', feedbackRef)"
        class="background-transparent mx-2"
      >
        <i class="fa-regular fa-circle-question feedback-icon"></i>
      </div>

      <!-- User Profile Menu -->
      <ng-container *ngIf="!isSubscriptionActive">
        <div (click)="toggleUserProfileMenu()">
          <div
            class="user-icon my-auto float-end ms-3"
            [attr.data-letters]="loggedInUser?.initialName"
          ></div>
        </div>
        <div
          id="userDropdown1"
          class="dropdown-menu shadow animated-grow-in user-dropdown-position-1 mx-1"
          aria-labelledby="userDropdown1"
          [ngClass]="showUserDropdown ? 'show' : ''"
        >
          <div
            class="dropdown-item user-menu-content"
            *ngFor="let userItem of userMenu"
            (click)="redirectToPage(userItem?.link, true)"
          >
            {{ userItem.title }}
          </div>
        </div>
      </ng-container>
    </div>
  </nav>

  <div (click)="lockScreen()" id="wrapper">
    <!-- Sidebar -->
    <div
      (click)="largeView ? handleWrapperClick($event) : ''"
      *ngIf="
        isAppOnline &&
        isSubscriptionActive &&
        (isAdmin || (isOnlyGuardAndDispatcher && !showWelfareCheckButton))
      "
      class="border-right"
      id="sidebar-wrapper"
      [ngClass]="navbarToggled ? 'hide' : 'show'"
    >
      <div class="list-group list-group-flush">
        <ng-container *ngIf="!showManageMenu">
          <!-- Normal menu items -->
          <div
            class="list-group-item list-group-item-action"
            (click)="redirectToPage(menu.link)"
            *ngFor="let menu of sideBarMenuItems"
          >
            <div class="menu-item" [id]="guideIdMapping?.[menu.link]">
              <span>{{ menu.title }}</span>
            </div>
          </div>
        </ng-container>

        <!-- <div class="list-group-item list-group-item-action" *ngIf="isAdmin">
          <div class="menu-item" (click)="toggleManageMenu()">
            <span class="fw-bold">{{
              showManageMenu ? "Back" : "Settings"
            }}</span>
          </div>
        </div> -->

        <!-- Submenu with transition -->
        <div
          class="submenu-container"
          [ngClass]="{ 'submenu-visible': showManageMenu }"
        >
          <div
            class="list-group-item list-group-item-action"
            *ngFor="let subMenu of settingItems"
            (click)="redirectToPage(subMenu.link)"
          >
            <div class="menu-item" [id]="guideIdMapping?.[subMenu.link]">
              <span>{{ subMenu.title }}</span>
            </div>
          </div>
        </div>
      </div>

      <!-- User Profile and clock in Section -->
      <div class="sidebar-footer">
        <!-- Install App -->
        <div
          *ngIf="!isAppInstalled && serviceWorkerEnabled && !largeView"
          class="w-100 p-2"
        >
          <button
            class="btn-lg btn btn-secondary w-100"
            (click)="installPWAApp()"
          >
            Install App
          </button>
        </div>

        <!-- Flex container for Clock In/Out, theme switcher, and profile icon -->
        <div
          class="d-flex justify-content-between align-items-center w-100 mb-md-2"
        >
          <!-- Clock In/Out Button -->
          <div class="p-2">
            <button
              id="clock-in-btn"
              class="btn btn-md w-100"
              (click)="clockedInOut()"
              [ngClass]="isClockedIn ? 'btn-secondary' : 'btn-primary'"
            >
              <span *ngIf="isClockedIn"
                ><i class="fa-solid fa-right-from-bracket me-2"></i
              ></span>
              <span *ngIf="!isClockedIn"
                ><i class="fa-solid fa-right-to-bracket me-2"></i
              ></span>
              {{ isClockedIn ? "Clock Out" : "Clock In" }}
            </button>
          </div>

          <!-- Theme Switcher -->
          <div
            id="theme-icon"
            class="theme-switcher mx-2 cursor-pointer"
            (click)="switchTheme()"
          >
            <span *ngIf="isDefaultTheme"
              ><i class="bi bi-moon-stars-fill fa-xl"></i
            ></span>
            <span *ngIf="!isDefaultTheme"
              ><i class="bi bi-sun-fill fa-xl"></i
            ></span>
          </div>

          <div
            id="settings-icon"
            *ngIf="isAdmin"
            (click)="toggleManageMenu()"
            [ngStyle]="{
              transform: showManageMenu ? 'rotate(90deg)' : 'rotate(0deg)',
              transition: 'transform 0.2s ease'
            }"
          >
            <i id="settings-icon" class="fa-solid fa-cog fa-2x"></i>
          </div>

          <!-- Profile Icon -->
          <!-- tabindex="0" makes the div focusable -->
          <div
            id="user-menu"
            tabindex="0"
            (focusout)="toggleUserProfileMenu(true)"
            (click)="toggleUserProfileMenu()"
            class="sidebar-user-icon"
            [attr.data-letters]="loggedInUser?.initialName"
          ></div>
        </div>

        <!-- User Dropdown (remains unchanged) -->
        <div
          class="dropdown-menu shadow animated-grow-in user-dropdown-position"
          aria-labelledby="userDropdown"
          [ngClass]="showUserDropdown ? 'show' : ''"
        >
          <div
            class="dropdown-item user-menu-content"
            *ngFor="let userItem of userMenu"
            (click)="redirectToPage(userItem?.link)"
          >
            {{ userItem.title }}
          </div>
        </div>
      </div>
    </div>

    <!-- /#sidebar-wrapper -->

    <!-- Page Content -->
    <div
      id="page-content-wrapper"
      (click)="handleWrapperClick($event)"
      [ngStyle]="isSubscriptionActive ? {} : { 'margin-left': '0px' }"
    >
      <div class="container-fluid my-3">
        @if(isInGracePeriod){
        <!--not active subscription remaining but is in grace period-->
        <div
          class="alert alert-danger text-center"
          [routerLink]="isSubscriberAdmin ? '/subscription' : ''"
        >
          <span class="cursor-pointer"> Payment Due</span>
        </div>
        }
        <div
          class="p-2 mt-2"
          *ngIf="!isSubscriptionActive && !isSubscriberAdmin"
        >
          <div class="alert alert-warning">Invalid Subscription</div>
        </div>
        <!--welfare check button STARTS-->
        <div
          class="text-center px-2 mt-1"
          *ngIf="
            isGuardUser &&
            showWelfareCheckButton &&
            isClockedIn &&
            isSubscriptionActive
          "
        >
          <button
            class="btn btn-primary btn-xl w-100"
            (click)="welfareCheckIn()"
          >
            Send Welfare Check
          </button>
        </div>
        <!--welfare check button ENDS-->
        <ng-container *ngIf="isAppOnline">
          <ng-container
            *ngIf="sosAlertCount > 0 && (isAdmin || isDispatchUser)"
          >
            <div class="d-flex m-2">
              <div class="flex-grow-1 me-1">
                <button
                  [class.blink]="sosAlertCount && (isAdmin || isDispatchUser)"
                  class="w-100 btn btn-lg rounded-1 fw-normal fs-5 text-capitalize"
                  (click)="showSOSAlerts()"
                >
                  SOS Alerts
                </button>
              </div>
              <div *ngIf="isAlertActive">
                <button class="btn btn-dark btn-lg" (click)="stopAlert()">
                  <i class="fa-solid fa-volume-xmark"></i>
                </button>
              </div>
            </div>
          </ng-container>

          <!-- Clock In/Out button for Guards STARTS-->
          <div
            class="text-center px-2 mt-4"
            *ngIf="
              isSubscriptionActive &&
              !showWelfareCheckButton &&
              !isClockedIn &&
              isOnlyGuardAndDispatcher &&
              !allowedPaths
            "
          >
            <button
              class="btn btn-primary btn-xl w-100"
              (click)="clockedInOut()"
            >
              <i class="fas fa-sign-out-alt"></i>
              Clock In
            </button>
          </div>
        </ng-container>

        <div
          *ngIf="
            isSuperUser ||
            ((isSubscriptionActive || allowedPaths) &&
              (isAdmin ||
                allowedPaths ||
                (isClockedIn && !showWelfareCheckButton))) ||
            isSubscriberAdmin
          "
        >
          <router-outlet></router-outlet>
        </div>

        <div id="gt-caret" *ngIf="windowScrolled === true">
          <i class="fa-solid fa-angles-up" (click)="scrollToTop()"></i>
        </div>
      </div>
    </div>
    <!-- /#page-content-wrapper -->
  </div>
</div>

<!-- ADD FEEDBACK POPOVER / DIALOG -->
<ng-template #feedbackRef let-feedbackRef="dialogRef">
  <div class="card dialog-box">
    <div class="card-header">
      Contact Form

      <span
        class="float-end"
        (click)="
          feedbackRef.close('close'); showHideDraggableFeedbackBtn(false)
        "
      >
        <i class="fa-solid fa-xmark"></i>
      </span>
    </div>
    <div class="p-1">
      <form [formGroup]="feedbackForm" class="row-cols-1 gy-2">
        <div class="col">
          <textarea
            class="form-control"
            cols="32"
            rows="10"
            wrap="hard"
            maxlength="340"
            placeholder="Need help or have feedback? Please tell us how we can help."
            formControlName="feedback"
          ></textarea>
        </div>
      </form>
    </div>
    <div class="row row-cols-2 mt-4">
      <div class="col">
        <button
          class="btn btn-dark btn-lg w-100"
          (click)="
            feedbackRef.close('close'); showHideDraggableFeedbackBtn(false)
          "
        >
          Cancel
        </button>
      </div>

      <div class="col">
        <button
          (click)="
            addfeedback();
            feedbackRef.close();
            showHideDraggableFeedbackBtn(false)
          "
          class="btn btn-primary btn-lg w-100"
          [disabled]="!feedbackForm.valid"
        >
          Submit
        </button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #notificationPopover let-data let-notificationPopover="dialogRef">
  <div
    *ngIf="notificationData?.length > 0"
    id="notificationDropdown"
    class="dialog-box card"
  >
    <div class="card-header">
      Notifications
      <span class="float-end" (click)="notificationPopover.close()">
        <i class="fa-solid fa-xmark"></i>
      </span>
    </div>

    <div
      *ngFor="let notification of notificationData"
      class="cursor-pointer gt-card mb-2"
    >
      <div class="d-flex small">
        <div
          class="flex-grow-1"
          (click)="
            redirectNotification(notification); notificationPopover.close()
          "
        >
          {{ notification.created_at | customDate : "d MMM y HH:mm" }}
        </div>
        <div>
          <span (click)="deleteNotification(notification)">
            <i class="fa-solid fa-xmark"></i>
          </span>
        </div>
      </div>

      <div class="small">{{ notification.notification_msg }}</div>
    </div>
    <div>
      <gtapp-pagination-control
        [pageSize]="rows"
        [pageNum]="pageNum"
        [previous]="previous"
        [totalRows]="totalRows"
        (onClickPagination)="onChangeNotificationPagination($event)"
        (onChangePagination)="onChangeNotificationPagination($event)"
      >
      </gtapp-pagination-control>
    </div>
    <div class="mt-2 px-1">
      <button
        *ngIf="notificationData?.length > 0"
        class="btn btn-dark btn-lg w-100"
        (click)="clearNotifications(); notificationPopover.close()"
      >
        Clear Notifications
      </button>
    </div>
  </div>

  <div class="dialog-box card" *ngIf="notificationData?.length === 0">
    <div class="card-header">You have no notifications</div>
  </div>
</ng-template>
