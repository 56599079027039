import {
  Component,
  EventEmitter,
  OnInit,
  Optional,
  Output,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { globalLicenseInfo, isLargeScreen } from '../../../../global.variable';
import { AppService } from '../../../app.service';
import { AddEditLicenseDetailsComponent } from '../../../shared/components/add-edit-license-details/add-edit-license-details.component';
import { ConfirmDialogComponent } from '../../../shared/components/confirm-dialog/confirm-dialog.component';
import { DialogRef } from '../../../shared/components/modal-dialogue/model-dialogue.ref';
import { ModelDialogueService } from '../../../shared/components/modal-dialogue/model-dialogue.service';
import { MultipleSubscribersComponent } from '../../../shared/components/multiple-subscribers/multiple-subscribers.component';
import { DataCheckService } from '../../../shared/services/data-check.service';
import { LoadingSpinnerService } from '../../../shared/services/loading-spinner.service';
import { ToasterService } from '../../../shared/services/toaster.service';
import { ProfileService } from '../../profile/profile.service';
import { ClientsService } from '../clients/clients.service';
import { UsersService } from '../users/users.service';

@Component({
  selector: 'gtapp-company-profile',
  templateUrl: './company-profile.component.html',
  styleUrl: './company-profile.component.scss',
})
export class CompanyProfileComponent implements OnInit {
  @ViewChild('companyDialog') templateRef: any = TemplateRef<any>;
  isAdmin: any;
  latLonSubscription: any;
  latLon: any;
  enteredAddresslookup: boolean = false;
  @Output() getEmitData = new EventEmitter();
  addrLookupData: any;
  companyDetails: any;
  companyForm: UntypedFormGroup = new UntypedFormGroup({
    name: new UntypedFormControl('', [Validators.required]),
    email: new UntypedFormControl('', [Validators.required, Validators.email]),
    phone: new UntypedFormControl(''),
    addressLookup: new UntypedFormControl(''),
    address1: new UntypedFormControl('', [Validators.required]),
    address2: new UntypedFormControl('', [Validators.required]),
    address3: new UntypedFormControl(''),
    city_name: new UntypedFormControl('', [Validators.required]),
    postcode: new UntypedFormControl(
      '',
      Validators.compose([Validators.required, Validators.max(99999999)])
    ),
    longitude: new UntypedFormControl('', [Validators.required]),
    latitude: new UntypedFormControl('', [Validators.required]),
    country_name: new UntypedFormControl('Australia'),
    state_code: new UntypedFormControl(''),
  });
  licenseForm: UntypedFormGroup = new UntypedFormGroup({
    issuer_state: new UntypedFormControl(null, [Validators.required]),
    license_class: new UntypedFormControl(null, [Validators.required]),
    license_number: new UntypedFormControl(null, [Validators.required]),
    valid_till: new UntypedFormControl(null, [Validators.required]),
  });
  formSubmitted: any;
  country: any;
  state: any;
  selectedItem: any;
  isIndividual: boolean = false;
  stateData: any = [];
  countryData: any;
  ipAddress: any;
  makeNewAddress: any;
  userData: any;
  showAddLicenseForm: boolean = false;
  companyLicenses: any = [];
  newlicense: any;
  classData: any = [];
  routeState: any;
  showHistory: boolean = false;
  showLicenseAddClass: boolean = false;
  licenseId: any;
  licenseClassData: any;
  largeView: Boolean = isLargeScreen;
  cardActionIcons: any = [
    {
      condition: function () {
        return {
          icon: 'fa-regular fa-trash-can fa-2x',
          status: 'primary',
          title: 'Delete License',
          confirm: true,
          show: 'top',
          type: 'copy',
        };
      },
    },
  ];
  licenseDetails = {
    individualDetails: globalLicenseInfo?.details,
  };
  // searchControl: FormControl = new FormControl();
  userLinkedSubscribers: any;
  subscriberLicenses: any;
  editBusinessName: boolean = false;
  isCompanyEditFirstTime: boolean = false;
  hasMultipleAssociation: any;
  showWebcam: boolean = false;
  dialogref: any;
  webcamImage: any;
  actualUploadFile: any;
  cameraError: any;
  subscriberLogoUrl: any;

  isDispatchUser: boolean = false;
  imageUploaded: boolean = false;
  companyDialogueStep: number = 1;

  constructor(
    private spinnerService: LoadingSpinnerService,
    private router: Router,
    private appService: AppService,
    private profileService: ProfileService,
    private clientService: ClientsService,
    @Optional() protected dialogRef: DialogRef,
    private userService: UsersService,
    private dialogService: ModelDialogueService,
    private route: ActivatedRoute,
    private dataCheckService: DataCheckService,
    private viewContainerRef: ViewContainerRef,
    private toasterService: ToasterService
  ) {
    this.getCompanyDetails();
    this.userData = this.appService.getUserData();
    this.hasMultipleAssociation =
      this.dataCheckService.hasMultipleSubscriberAssociation();
    this.isAdmin = this.dataCheckService.isUserAdmin();
    this.isDispatchUser = this.dataCheckService.isDispatchUser();
    this.routeState = this.route.snapshot.paramMap.get('state');
    this.getStates(1);
  }

  ngOnInit(): void {
    if (this.routeState) {
      setTimeout(() => {
        this.openTemplate();
      }, 100);
    }
  }

  getCompanyDetails() {
    this.spinnerService.show();
    this.profileService.fetchCompanyDetails().subscribe((res: any) => {
      if (res['status'] === 'success') {
        this.spinnerService.hide();
        this.companyDetails = res?.data;

        this.companyForm.reset();
        this.formatCompanyData();
      }
      this.spinnerService.hide();
    });
  }

  formatCompanyData() {
    // if (this.companyDetails?.phone?.toString().length === 9) {
    //   this.companyDetails.phone = '0' + this.companyDetails?.phone?.toString();
    // }
    this.companyForm.patchValue({
      name: this.companyDetails?.name,
      email: this.companyDetails?.email,
      phone: this.companyDetails?.phone,
      address1: this.companyDetails?.address?.address1,
      address2: this.companyDetails?.address?.address2,
      address3: this.companyDetails?.address?.address3,
      city_name: this.companyDetails?.address?.city_name,
      postcode: this.companyDetails?.address?.postcode,
      state_code: this.companyDetails?.address?.state_code,
      country_name: this.companyDetails?.address?.country_name,
      latitude: this.companyDetails?.address?.latitude,
      longitude: this.companyDetails?.address?.longitude,
    });

    if (this.companyDetails?.license) {
      this.companyLicenses = this.companyDetails?.license;
    }
    if (this.companyDetails?.logo_url) {
      this.subscriberLogoUrl = this.companyDetails?.logo_url;
      this.imageUploaded = true;
    } else {
      this.subscriberLogoUrl = 'assets/images/no-logo-available.avif';
    }
  }
  onAddressSearch(addressSearch: any) {
    if (addressSearch?.target?.value?.length > 2) {
      this.profileService
        .addressLookup(addressSearch.target.value)
        .subscribe((res: any) => {
          this.addrLookupData = res['data'];
        });
    }
  }
  addressSelected(address: any) {
    if (address) {
      this.addrLookupData = undefined;
      this.companyForm.patchValue({
        addressLookup: address?.full_address,
        address1: address?.address1,
        address2: address?.address2,
        address3: address?.address3,
        city_name: address?.city_name,
        postcode: address?.postcode,
        state_code: address?.state_code,
        country_name: address?.country_name,
        latitude: address?.latitude,
        longitude: address?.longitude,
      });
      this.enteredAddresslookup = true;
      var element = <HTMLInputElement>document.getElementById('submitbtn');
      if (element) {
        element.disabled = false;
      }
    }
  }
  closeUpdateCustomer() {
    this.getEmitData.emit(false);
  }
  onCloseDialogue() {
    this.dialogRef.close(false);
  }

  makeNewAddressField() {
    this.makeNewAddress = true;
  }

  getStates(countryId: any) {
    this.appService.getStates(+countryId).subscribe((response: any) => {
      if (response['status'] == 'success') {
        this.stateData = response['data'];
      }
    });
  }
  goBack() {
    this.router.navigate(['/profile']);
  }
  removeUserAssociation() {
    let dialogMsg = `WARNING: This action cannot be reversed.`;
    const dialogRef = this.dialogService.open(ConfirmDialogComponent, {
      data: {
        title: 'Leave Guard Tracker',
        message: dialogMsg,
      },
    });
    dialogRef.afterClosed().subscribe((value) => {
      if (value === true) {
        this.spinnerService.show();
        this.profileService.removeUserAssociation().subscribe((response) => {
          if (response['status'] == 'success') {
            this.toasterService.setMessage({
              successMessage: response['message'],
              errorMessage: '',
            });
            this.appService.logOut();
            this.router.navigate(['/login']);
          } else {
            this.toasterService.setMessage({
              successMessage: '',
              errorMessage: response['message'],
            });
          }
          this.spinnerService.hide();
        });
      } else {
        this.dialogRef.close();
      }
    });
  }
  updateData() {
    if (this.companyForm.valid) {
      this.spinnerService.show();
      var requestData = this.companyForm.value;
      this.clientService
        .updateCompanyDetails(requestData)
        .then((response: any) => {
          if (response['status'] == 'success') {
            this.spinnerService.hide();
            this.companyDetails = response['data'];
            this.toasterService.setMessage({
              successMessage: response['message'],
              errorMessage: '',
            });

            this.companyDetails = response?.data;
            this.companyForm.reset();
            this.formatCompanyData();

            this.routeState = undefined;
            this.isCompanyEditFirstTime = false;
          } else {
            this.spinnerService.hide();
            this.toasterService.setMessage({
              successMessage: '',
              errorMessage: response['message'],
            });
          }
        });
    }
  }
  addSubscriberLicense() {
    const dialogRef = this.dialogService.open(AddEditLicenseDetailsComponent, {
      data: { companyLicense: true },
    });
    dialogRef.afterClosed().subscribe((value: any) => {
      if (value == 'close') {
      }
    });
  }

  addNewLicense() {
    const dialogRef = this.dialogService.open(AddEditLicenseDetailsComponent, {
      data: {
        companyLicense: true,
      },
    });
    dialogRef.afterClosed().subscribe((value: any) => {
      if (value !== 'close') {
        this.companyLicenses = value;
      }
    });
  }

  selectState(data: any) {
    this.userService.getClassByState(data.id).subscribe((response: any) => {
      if (response['status'] == 'success') {
        this.classData = response['data'];
        this.licenseClassData = response['data'];
      } else {
        this.toasterService.setMessage({
          successMessage: '',
          errorMessage: response['message'],
        });
      }
    });
  }
  addNewClass(newLicense: string) {
    if (this.licenseForm.value['issuer_state']) {
      let data = {
        issuer_state: this.licenseForm.value['issuer_state'],
        license_class_name: newLicense,
      };
      this.userService.addNewClass(data).subscribe((response: any) => {
        this.classData = [...this.classData, response['data']];
        this.showLicenseAddClass = false;
        this.licenseForm.controls['license_class'].setValue(
          response['data'].license_class_name
        );
      });
    } else {
      this.toasterService.setMessage({
        errorMessage: 'Please select the state to add new licence guard.',
      });
    }
  }

  deleteLicense(license: any) {
    let dialogMsg = 'Are you sure you want to delete this license?';
    const dialogRef = this.dialogService.open(ConfirmDialogComponent, {
      data: {
        title: 'Delete License',
        message: dialogMsg,
      },
    });
    dialogRef.afterClosed().subscribe((value) => {
      if (value === true) {
        this.spinnerService.show();
        this.clientService
          .deleteLicense(license.id, 1)
          .then((response: any) => {
            if (response['status'] == 'success') {
              this.spinnerService.hide();
              this.toasterService.setMessage({
                successMessage: response['message'],
                errorMessage: '',
              });
              this.companyLicenses = response['data'];
            } else {
              this.spinnerService.hide();
              this.toasterService.setMessage({
                successMessage: '',
                errorMessage: response['message'],
              });
            }
          });
      }
    });
  }

  onLicenceClassSearch(event: any) {
    this.showLicenseAddClass = false;
    if (event?.target?.value?.length === 0) {
      this.licenseClassData = this.classData;
    } else {
      this.licenseClassData = this.classData.filter((item: any) =>
        item.license_class_name
          ?.toLowerCase()
          .includes(event?.target?.value?.toLowerCase())
      );
      if (!this.licenseClassData?.length) this.showLicenseAddClass = true;
    }
  }
  onActionClick(event: any) {
    this.deleteLicense(event?.event);
  }
  openTemplate(template: TemplateRef<any> = this.templateRef) {
    this.formatCompanyData();
    const dialogref = this.dialogService.open(
      template,
      {
        data: {},
      },
      this.viewContainerRef
    );
  }
  changeSubscriber() {
    const fileImportDialogRef = this.dialogService.open(
      MultipleSubscribersComponent,
      {}
    );
    fileImportDialogRef.afterClosed().subscribe((value) => {
      if (value !== 'close') {
        window.location.reload();
      }
    });
  }

  forceUppercaseConditionally(event: any) {
    this.companyForm.controls['city_name'].setValue(
      event.target.value.toUpperCase()
    );
  }
  openCamPopup() {
    this.showWebcam = true;
    var button: any = document.getElementById('fileInput');
    button.click();
  }
  onFileSubmit() {
    if (this.actualUploadFile) {
      this.spinnerService.show();
      let fileData: FormData = new FormData();
      fileData.append('file', this.actualUploadFile);

      this.appService
        .formDataApi('company_profile', fileData, false, { change_logo: 1 })
        .then((response: any) => {
          if (response['status'] == 'success') {
            this.companyDetails = response?.data;
            this.companyForm.reset();
            this.formatCompanyData();
            window.location.reload();
          } else {
            this.toasterService.setMessage({
              successMessage: '',
              errorMessage: response['message'],
            });
          }
          this.spinnerService.hide();
        });
    }
  }
  removeImage() {
    this.spinnerService.show();
    this.clientService
      .updateCompanyDetails({}, { remove_logo: 1 })
      .then((response: any) => {
        if (response['status'] == 'success') {
          this.spinnerService.hide();

          this.companyDetails = response?.data;
          this.companyForm.reset();
          this.formatCompanyData();
          window.location.reload();
        } else {
          this.spinnerService.hide();
          this.toasterService.setMessage({
            successMessage: '',
            errorMessage: response['message'],
          });
        }
      });
  }
  async fileCheck(fileExtension: string, fileSize: number) {
    return new Promise((resolve) => {
      if (!['jpeg', 'jpg', 'png'].includes(fileExtension)) {
        resolve('Unsupported file format');
      } else if (fileSize / (1024 * 1024) > 2) {
        resolve('File Too Large');
      } else {
        resolve(null);
      }
    });
  }
  async handleFileInput(item: any) {
    let file = item.files.item(0);
    let fileSize: number = file.size;
    let fileName: string = file.name;
    let fileExtension: string = fileName.split('.').pop() || '';
    let fileCheckError = await this.fileCheck(fileExtension, fileSize);
    if (fileCheckError) {
      this.toasterService.setMessage({
        successMessage: '',
        errorMessage: fileCheckError,
      });
    } else {
      this.actualUploadFile = item.files.item(0);
      this.onFileSubmit();
    }
  }
  mobileNumberCheck() {
    if (!this.companyForm.value?.phone) {
      this.companyForm.controls['phone'].setValue(null);
      this.companyForm.controls['phone'].removeValidators([
        Validators.minLength(10),
        Validators.maxLength(10),
        Validators.pattern('^((\\+91-?)|0)?[0-9]{10}$'),
      ]);
    } else {
      this.companyForm.controls['phone'].setValidators([
        Validators.minLength(10),
        Validators.maxLength(10),
        Validators.pattern('^((\\+91-?)|0)?[0-9]{10}$'),
      ]);
    }
  }
}
