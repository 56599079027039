<ng-container *ngIf="apidataUpdation === true && !offlineMode">
  <div class="alert alert-primary mb-3">
    <div class="fw-bold">
      Fetching Data from Server

      <div class="spinner-grow float-end" role="status"></div>
    </div>
  </div>
</ng-container>
<div *ngIf="detailData && !updateTask">
  <div
    class="main-section"
    [ngStyle]="{
      'padding-bottom': scheduleCheck && !largeView ? '200px' : '50px'
    }"
  >
    <div class="row row-cols-1 row-cols-lg-3 gt-card">
      <div class="col">
        <dl>
          <div class="d-flex">
            <div class="flex-grow-1">
              <dt
                [ngClass]="isAdmin || isDispatchUser ? 'cursor-pointer' : ''"
                (click)="
                  isAdmin || isDispatchUser
                    ? openTemplate(updatePatrolNameTemplate, 'name')
                    : null
                "
              >
                Patrol Name
                <span>
                  <i
                    class="fa-solid fa-pen-to-square ms-2"
                    *ngIf="isAdmin || isDispatchUser"
                  ></i
                ></span>
              </dt>
              <dd class="fw-bold">
                {{ detailData?.name }}
              </dd>
            </div>
            <div *ngIf="patrolRouteStatusKey === 2 && isAdmin">
              <!-- allow admin to send missed cp scan report on demand-->
              <button
                class="btn btn-primary btn-sm me-lg-2"
                (click)="generateMissedReport()"
              >
                Missed Scan Report
              </button>
            </div>
          </div>
        </dl>

        <div class="row row-cols-2">
          <div class="col">
            <dl>
              <!-- Date/Duration Section -->
              <dt>Start Date</dt>
              <dd>
                {{
                  detailData?.schedule_event_date
                    ? (detailData?.schedule_event_date | customDate : "d MMM y")
                    : (detailData?.roster_scheduler?.start_day
                      | customDate : "d MMM y")
                }}
              </dd>
            </dl>
          </div>
          <div class="col">
            <dl>
              <dt
                [ngClass]="isAdmin || isDispatchUser ? 'cursor-pointer' : ''"
                (click)="
                  canScheduleBeUpdated && (isAdmin || isDispatchUser)
                    ? openScheduleTemplate(changeScheduleTemplateRef)
                    : ''
                "
              >
                Shift [Start - End]
                <i
                  class="fa-solid fa-pen-to-square ms-2"
                  *ngIf="canScheduleBeUpdated && (isAdmin || isDispatchUser)"
                ></i>
              </dt>
              <dd>
                {{ detailData?.roster_scheduler?.start_time }} -
                {{ detailData?.roster_scheduler?.end_time }}
              </dd>
            </dl>
          </div>
          <div class="col">
            <dl>
              <!-- Job Duration Section -->
              <dt>Job Duration</dt>
              <dd>{{ scheduleDuration }} Hours</dd>
            </dl>
          </div>
          <div class="col">
            <dl
              *ngIf="
                showWelfareItem &&
                (isAdmin || isDispatchUser || detailData?.interval_check > 0)
              "
            >
              <!-- Welfare Checks Section -->
              <dt
                [ngClass]="isAdmin || isDispatchUser ? 'cursor-pointer' : ''"
                (click)="
                  isAdmin || isDispatchUser
                    ? openIntervalCheckTemplate(welfareIntervalTemplate)
                    : ''
                "
              >
                Welfare Checks
                <i
                  class="fa-solid fa-pen-to-square"
                  *ngIf="isAdmin || isDispatchUser"
                ></i>
              </dt>
              <dd>{{ detailData?.interval_check }} mins</dd>
            </dl>
          </div>
        </div>
      </div>
      <div class="col">
        <dl *ngIf="isAdmin || isDispatchUser">
          <dt
            (click)="openTemplate(updateAssigneeTemplate, 'assignee')"
            [ngClass]="isAdmin || isDispatchUser ? 'cursor-pointer' : ''"
          >
            {{
              detailData?.subscriber_users?.length > 1
                ? "Assigned Guards"
                : "Assigned Guard"
            }}
            <i class="fa-solid fa-up-right-from-square"></i>
          </dt>

          <dd (click)="openTemplate(updateAssigneeTemplate, 'assignee')">
            <div class="d-flex">
              <div class="flex-grow-1 d-flex align-items-center">
                {{ detailData?.subscriber_users[0]?.full_name }}
              </div>
              <div>
                <button
                  class="btn btn-outline-secondary btn-sm"
                  *ngIf="detailData?.subscriber_users?.length > 1"
                >
                  + {{ detailData?.subscriber_users?.length - 1 }} more
                </button>
              </div>
            </div>
            <div
              *ngIf="!detailData?.subscriber_users?.length"
              style="color: var(--color-danger-500)"
            >
              Not Assigned
            </div>
          </dd>
        </dl>
        <dl *ngIf="isAdmin || isDispatchUser || detailData?.patrol_brief">
          <!-- Patrol Information Section -->
          <dt
            [ngClass]="isAdmin || isDispatchUser ? 'cursor-pointer' : ''"
            (click)="
              isAdmin || isDispatchUser
                ? openPatrolBriefInformationTemplate(requestRef)
                : ''
            "
          >
            Patrol Information
            <i
              class="fa-solid fa-pen-to-square"
              *ngIf="isAdmin || isDispatchUser"
            ></i>
          </dt>
          <dd class="gt-multi-line">
            {{ detailData?.patrol_brief ? detailData?.patrol_brief : "N/A" }}
          </dd>
        </dl>

        <dl
          *ngIf="
            isAdmin || isDispatchUser || detailData?.patrol_brief_files?.length
          "
        >
          <!-- File Attachments Section -->
          <label for="file-upload">
            <dt class="d-inline-block">
              {{
                isAdmin || isDispatchUser
                  ? "Attachments [" +
                    detailData?.patrol_brief_files?.length +
                    " / 3 Max]"
                  : "Attachments"
              }}

              <i
                class="fas fa-plus-circle"
                *ngIf="
                  (isAdmin || isDispatchUser) &&
                  detailData?.patrol_brief_files?.length < 3
                "
              ></i>
            </dt>
          </label>
          <input
            *ngIf="
              (isAdmin || isDispatchUser) &&
              detailData?.patrol_brief_files?.length < 3
            "
            id="file-upload"
            type="file"
            accept="application/pdf, image/*"
            (change)="handleFileInput($event)"
          />
          <dd>
            <div class="row p-1">
              <div
                *ngFor="
                  let file of detailData?.patrol_brief_files;
                  let i = index
                "
                class="col-12 mb-2 d-flex align-items-center cursor-pointer"
              >
                <span (click)="onOpenFile(file)">
                  <span class="badge bg-primary me-2">{{
                    file?.extension
                  }}</span>
                  <span> File {{ i + 1 }}</span>
                </span>
                <span
                  class="ms-5"
                  (click)="deleteFile(file)"
                  *ngIf="isAdmin || isDispatchUser"
                >
                  <i class="fa-regular fa-trash-can fa-2x"></i>
                </span>
              </div>
            </div>
          </dd>
        </dl>
      </div>
      <div class="col">
        <dl>
          <dt [ngClass]="isAdmin || isDispatchUser ? 'cursor-pointer' : ''">
            <div class="d-flex">
              <div
                class="flex-grow-1"
                (click)="
                  isAdmin === true || isDispatchUser === true
                    ? openTemplate(updateCheckpointTemplate, 'checkpoint')
                    : ''
                "
              >
                Checkpoints [{{ scannedCheckpointCount }} /
                {{ detailData?.check_points?.length }}]

                <span *ngIf="isAdmin || isDispatchUser">
                  <i class="fa-solid fa-pen-to-square"></i>
                </span>
              </div>
              <div *ngIf="detailData?.check_points?.length > 5">
                <button
                  (click)="
                    openTemplate(chekpointListTemplate, 'showAllCheckpoints')
                  "
                  class="btn btn-outline-secondary btn-sm"
                >
                  + {{ detailData?.check_points?.length - 5 }} more
                </button>
              </div>
            </div>
          </dt>
          <dd>
            <div
              *ngFor="
                let item of detailData?.check_points?.slice(0, 5);
                let i = index
              "
              class="d-flex mb-3"
            >
              <ng-template
                *ngTemplateOutlet="
                  selectedCheckpointDetail;
                  context: { value: item }
                "
              ></ng-template>
            </div>
          </dd>
        </dl>
      </div>

      <ng-container *ngIf="patrolLogUpdates?.length && !largeView">
        <div
          class="d-flex flex-row-reverse"
          *ngIf="patrolLogUpdates?.length > 1"
        >
          <dl>
            <dt (click)="changeSortOrder = !changeSortOrder">
              <button class="btn btn-sm btn-dark">
                Sort
                <span>
                  <i class="fa-solid fa-sort"></i>
                </span>
              </button>
            </dt>
          </dl>
        </div>

        <div
          *ngFor="
            let msg of changeSortOrder
              ? patrolLogUpdates
              : patrolLogUpdates.slice().reverse();
            let i = index
          "
        >
          <dt>
            <!--update details section-->

            <span *ngIf="msg?.file">
              Photo
              {{ msg?.event_action === 19 ? "[Device Library]" : "[Camera]" }}
              |
            </span>
            <span class="float-end" *ngIf="msg?.cachedData">
              <i class="fa-solid fa-triangle-exclamation text-warning"></i>
            </span>

            {{ msg?.updated_at | customDate : "d MMM y HH:mm" }} |
            {{ subscriberUserId == msg?.subs_user_id ? "You" : msg?.user_name }}
            <span
              *ngIf="
                msg?.history_changes?.length &&
                (subscriberUserId == msg?.subs_user_id ||
                  isAdmin ||
                  isDispatchUser)
              "
            >
              | Edited [{{
                (msg?.history_changes | misc : "lastCommentEditedDateTime")
                  ?.updated_at | customDate : "d MMM y HH:mm"
              }}]</span
            >

            <span
              class="float-end me-2"
              (click)="
                subscriberUserId == msg?.subs_user_id &&
                !offlineMode &&
                [1, 2, 7].includes(msg?.event_action)
                  ? editComment(msg, commentRef)
                  : ''
              "
              *ngIf="
                subscriberUserId == msg?.subs_user_id &&
                !offlineMode &&
                [1, 2, 7].includes(msg?.event_action)
              "
            >
              <i class="fa-solid fa-pen-to-square"></i>
            </span>
          </dt>

          <dd class="mb-4">
            <!-- comment / photo -->

            <div *ngIf="msg?.event_action === 9">CHECKED IN</div>

            <div>
              <span class="gt-multi-line">
                {{
                  msg?.detail === "null" || msg?.detail === "undefined"
                    ? ""
                    : msg?.detail
                }}
              </span>
              <span *ngIf="msg?.event_action === 0" class="small-font"
                >[Checkpoint Scan]</span
              >
              <span *ngIf="msg?.event_action === 6" class="small-font"
                >[QR Scan]</span
              >
            </div>

            <ng-container *ngIf="msg?.file">
              <ng-container *ngIf="msg?.cachedData">
                <img
                  class="zoomImage"
                  (click)="onOpenImage($event, msg)"
                  [src]="getImgSrc(msg?.file)"
                  height="120"
              /></ng-container>
              <ng-container *ngIf="!msg?.cachedData">
                <img
                  class="zoomImage"
                  (click)="onOpenImage($event, msg)"
                  src="{{ msg?.file }}"
                  height="120"
              /></ng-container>
            </ng-container>
          </dd>
        </div>
      </ng-container>
      <ng-container *ngIf="offlineMode">
        <!--offline data saved in the indexed db-->
        <div *ngFor="let msg of offlineSavedData">
          <dt>
            <!--update details section-->

            {{ msg?.updated_at * 1000 | customDate : "d MMM y HH:mm" }} |
            <span class="text-danger">Waiting for connection</span>
          </dt>

          <dd class="mb-4">
            <!-- comment / photo -->

            <div>
              <span class="gt-multi-line">
                {{
                  msg?.comment === "null" || msg?.comment === "undefined"
                    ? ""
                    : msg?.comment
                }}
              </span>
              <span *ngIf="msg?.event_action === 0" class="small-font"
                >[Checkpoint Scan]</span
              >
              <span *ngIf="msg?.event_action === 6" class="small-font"
                >[QR Scan]</span
              >
            </div>
            <img
              class="zoomImage"
              *ngIf="msg?.file"
              [src]="getImgSrc(msg?.file)"
              height="120"
              width="120"
              alt="Unable to preview photo while offline"
            />
          </dd>
        </div>
      </ng-container>
      <ng-container *ngIf="largeView">
        <div class="row row-cols-1 row-cols-md-2 col-lg-6">
          <ng-container
            *ngIf="patrolRouteStatus?.[patrolRouteStatusKey]?.value"
          >
            <div>
              <button
                class="btn-lg rounded-0 w-100"
                [ngStyle]="patrolRouteStatus?.[patrolRouteStatusKey]?.style"
              >
                {{ patrolRouteStatus?.[patrolRouteStatusKey]?.value }}
              </button>
            </div>
          </ng-container>

          <div class="col">
            <button
              class="btn btn-primary btn-lg rounded-0 w-100"
              (click)="updateTask = 'addClientComment'"
            >
              + Add Comment
            </button>
          </div>
        </div>
      </ng-container>
    </div>
  </div>

  <div class="section" *ngIf="!largeView">
    <div class="section bottom-section" id="bottom-section-fixed">
      <!--online buttons (check conditions are different)-->

      <div class="p-2">
        <div [ngStyle]="patrolRouteStatus?.[patrolRouteStatusKey]?.style">
          {{ patrolRouteStatus?.[patrolRouteStatusKey]?.value }}
        </div>

        <ng-container *ngIf="scheduleCheck">
          <div class="mb-2" *ngIf="!hasQrCheckpoint">
            <div
              class="d-flex align-items-center justify-content-center w-100 bg-primary btm-btn-height"
              (click)="updateTask = 'scanCheckPoint'"
            >
              Scan Checkpoint
            </div>
          </div>
          <div
            *ngIf="hasQrCheckpoint"
            class="mb-2 d-flex justify-content-between"
          >
            <div
              class="me-1 d-flex align-items-center justify-content-center w-100 bg-primary btm-btn-height"
              (click)="updateTask = 'scanCheckPoint'"
            >
              Scan Checkpoint
            </div>
            <div
              class="ms-1 d-flex align-items-center justify-content-center w-100 bg-primary btm-btn-height"
              (click)="openQRCode()"
            >
              Scan QR
            </div>
          </div>
          <div class="d-flex justify-content-between">
            <div
              class="d-flex align-items-center justify-content-center flex-fill me-1 border border-primary btm-btn-height"
              (click)="updateTask = 'addClientComment'"
            >
              <i class="fa-regular fa-message fa-2x"></i>
            </div>
            <div
              class="d-flex align-items-center justify-content-center flex-fill ms-1 border border-primary btm-btn-height"
              (click)="updateTask = 'addClientPhoto'"
            >
              <i class="fa-solid fa-camera fa-2x"></i>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
  <ng-container *ngIf="largeView">
    <gtapp-gt-table
      [desktopTableUIData]="
        isAdmin || isDispatchUser ? patrolUpdateLog : patrolLogUpdates
      "
      [desktopTableDetails]="patrolLogTableDetail"
      [otherParams]="{ paginationData: true }"
      (rowClick)="onTableRowClick($event)"
    >
    </gtapp-gt-table>
    <!-- SELECTED DETAILS -->
    <ng-container *ngIf="selectedEvent">
      <gtapp-selected-event-detail
        [selectedEvent]="selectedEvent"
        selectedEventFrom="patrol"
        [extraCheck]="!offlineMode"
        (editUserComment)="editComment($event, commentRef)"
      ></gtapp-selected-event-detail>
    </ng-container>
  </ng-container>
</div>

<ng-container
  *ngIf="
    ['scanCheckPoint', 'addClientComment', 'addClientPhoto'].includes(
      updateTask
    )
  "
>
  <gtapp-update-events
    [detailType]="updateTask"
    [details]="{
      filterPatrolData: filterPatrolData,
      patrolRouteId: detailData?.id
    }"
    (emitData)="closeDetailedHome()"
  >
  </gtapp-update-events>
</ng-container>

<ng-template
  #updateCheckpointTemplate
  let-data
  let-updateCheckpointTemplate="dialogRef"
>
  <div class="card dialog-box cp-dialogue-width">
    <div class="card-header">
      Select Checkpoints

      <span *ngIf="selectedCheckpoints?.length">
        [{{ selectedCheckpoints?.length }} Selected]
      </span>
      <span class="float-end" (click)="updateCheckpointTemplate.close()">
        <i class="fa-solid fa-xmark"></i>
      </span>
    </div>

    <div class="row row-cols-2 my-3" *ngIf="!largeView">
      <div class="col">
        <button
          class="btn btn-lg btn-dark w-100"
          (click)="updateCheckpointTemplate.close()"
        >
          Cancel
        </button>
      </div>

      <div class="col">
        <button
          type="submit"
          class="btn btn-lg btn-primary w-100"
          [disabled]="checkpointUpdateDisabling()"
          (click)="updatePatrolRoute('checkpoint')"
        >
          Submit
        </button>
      </div>
    </div>
    <div>
      <ng-container *ngIf="!largeView">
        <!-- Tabs Navigation for mobile view-->
        <ul class="nav nav-tabs" id="checkpointsTab" role="tablist">
          <li class="nav-item" role="presentation">
            <a
              class="nav-link active"
              id="all-checkpoints-tab"
              data-bs-toggle="tab"
              href="#all-checkpoints"
              role="tab"
              aria-controls="all-checkpoints"
              aria-selected="true"
              >Checkpoints</a
            >
          </li>
          <li class="nav-item" role="presentation">
            <a
              class="nav-link"
              id="selected-checkpoints-tab"
              data-bs-toggle="tab"
              href="#selected-checkpoints"
              role="tab"
              aria-controls="selected-checkpoints"
              aria-selected="false"
              >Added
              <span class="badge bg-primary ms-1">{{
                selectedCheckpoints?.length
              }}</span>
            </a>
          </li>
        </ul>

        <!-- Tabs Content -->
        <div class="tab-content" id="checkpointsTabContent">
          <!-- All Checkpoints Tab -->
          <div
            class="tab-pane fade show active"
            id="all-checkpoints"
            role="tabpanel"
            aria-labelledby="all-checkpoints-tab"
          >
            <ng-template
              *ngTemplateOutlet="
                checkPointSearchSelectTemplate;
                context: { value: { search: true } }
              "
            ></ng-template>
          </div>

          <!-- Selected Checkpoints Tab -->
          <div
            class="tab-pane fade"
            id="selected-checkpoints"
            role="tabpanel"
            aria-labelledby="selected-checkpoints-tab"
          >
            <ng-template
              *ngTemplateOutlet="checkPointSearchSelectTemplate; context: {}"
            ></ng-template>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="largeView">
        <div class="row row-cols-2">
          <div class="col">
            <ng-template
              *ngTemplateOutlet="
                checkPointSearchSelectTemplate;
                context: { value: { search: true } }
              "
            ></ng-template>
          </div>
          <div class="col">
            <ng-template
              *ngTemplateOutlet="checkPointSearchSelectTemplate; context: {}"
            ></ng-template>
          </div>
        </div>
      </ng-container>
    </div>

    <div class="row row-cols-2 my-3">
      <div class="col">
        <button
          class="btn btn-lg btn-dark w-100"
          (click)="updateCheckpointTemplate.close()"
        >
          Cancel
        </button>
      </div>

      <div class="col">
        <button
          type="submit"
          class="btn btn-lg btn-primary w-100"
          [disabled]="checkpointUpdateDisabling()"
          (click)="updatePatrolRoute('checkpoint')"
        >
          Submit
        </button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template
  #updateAssigneeTemplate
  let-data
  let-updateAssigneeTemplate="dialogRef"
>
  <div class="dialog-box card">
    <div class="card-header">
      Select Assignees

      <span *ngIf="selectedAssignees?.length">
        [{{ selectedAssignees?.length }} Selected]
      </span>

      <span class="float-end" (click)="updateAssigneeTemplate.close()">
        <i class="fa-solid fa-xmark"></i>
      </span>
    </div>
    <div>
      <div class="mt-3">
        <gtapp-auto-complete
          [data]="assigneeSearchList"
          placeHolder="Search Guard"
          searchKeyword="full_name"
          [itemTemplate]="assigneeTemplate"
          (inputChanged)="searchAssignee($event)"
          (selected)="addSelectedAssignee($event)"
        >
        </gtapp-auto-complete>
        <ng-template #assigneeTemplate let-item>
          <div>
            {{ item?.full_name }}
          </div>
        </ng-template>
        <div class="overflow-assignee">
          <table class="table my-2">
            <tbody>
              <tr *ngFor="let assignee of assigneeList" class="cursor-pointer">
                <td (click)="addRemovessignee(assignee)">
                  <div class="d-flex">
                    <div>
                      <div class="form-floating">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          [checked]="assignee?.selected"
                        />
                      </div>
                    </div>
                    <div class="flex-grow-1 ms-2">
                      {{ assignee?.full_name }}
                    </div>
                    <div (click)="openAssigneeDetail(assignee)">
                      <i class="fa-solid fa-circle-info"></i>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div
        class="mt-3"
        *ngIf="
          patrolRouteAssigneeTotalPages > 1 &&
          patrolRouteAssigneeTotalCount != assigneeList?.length
        "
      >
        <button
          class="btn btn-secondary btn-lg"
          (click)="onLoadMoreAssignees()"
        >
          Load More
        </button>
      </div>

      <div class="row row-cols-2 mt-4">
        <div class="col">
          <button
            class="btn btn-lg w-100 btn-dark"
            (click)="updateAssigneeTemplate.close()"
          >
            Cancel
          </button>
        </div>

        <div class="col">
          <button
            class="btn btn-primary btn-lg w-100"
            [disabled]="assigneeUpdateDisabling()"
            (click)="updatePatrolRoute('assignee')"
          >
            Update
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template
  #changeScheduleTemplateRef
  let-changeScheduleTemplateRef="dialogRef"
>
  <div class="dialog-box card">
    <div class="card-header">
      Change Schedule

      <span class="float-end" (click)="changeScheduleTemplateRef.close()">
        <i class="fa-solid fa-xmark"></i>
      </span>
    </div>
    <div class="form-floating mb-3">
      <input
        type="datetime-local"
        class="form-control"
        placeholder="End Time"
        [(ngModel)]="endDateTime"
        [min]="validEndDate"
        (change)="changeEndDateTime($event)"
      />
      <label>End Time</label>
    </div>
    <div class="row row-cols-2 mt-4">
      <div class="col">
        <button
          class="btn btn-lg w-100 btn-dark"
          (click)="changeScheduleTemplateRef.close()"
        >
          Cancel
        </button>
      </div>

      <div class="col">
        <button
          class="btn btn-primary btn-lg w-100"
          (click)="updateSchedule(); changeScheduleTemplateRef.close()"
        >
          Update
        </button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #updatePatrolNameTemplate let-updatePatrolNameTemplate="dialogRef">
  <div class="dialog-box card">
    <div class="card-header">
      Update Patrol Name

      <span class="float-end" (click)="updatePatrolNameTemplate.close()">
        <i class="fa-solid fa-xmark"></i>
      </span>
    </div>

    <div class="form-floating mt-3">
      <input
        class="form-control"
        id="mobassigneeField"
        [(ngModel)]="newPatrolName"
        type="text"
        autocomplete="off"
        placeholder="Patrol Route Name"
      />
      <label>Patrol Route Name</label>
    </div>
    <div class="row row-cols-2 mt-4">
      <div class="col">
        <button
          class="btn btn-lg w-100 btn-dark"
          (click)="updatePatrolNameTemplate.close()"
        >
          Cancel
        </button>
      </div>

      <div class="col">
        <button
          class="btn btn-primary btn-lg w-100"
          [disabled]="newPatrolName === detailData?.name"
          (click)="updatePatrolRoute('name')"
        >
          Update
        </button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #welfareIntervalTemplate let-welfareIntervalTemplate="dialogRef">
  <div class="dialog-box card">
    <div class="card-header">
      Welfare Check Interval
      <span class="float-end" (click)="welfareIntervalTemplate.close()">
        <i class="fa-solid fa-xmark"></i>
      </span>
    </div>

    <div class="form-floating">
      <select
        class="form-select"
        id="welfareChecks"
        [(ngModel)]="selectedInterval"
      >
        <option *ngFor="let interval of intervalData" [value]="interval.value">
          {{ interval.name }}
        </option>
      </select>
      <label for="">Welfare Check Interval</label>
    </div>

    <div *ngIf="selectedInterval > 0" class="alert">
      <div class="mb-2">
        Guards will be reminded to submit a welfare check
        {{ welfareReminderDict?.[selectedInterval]  }} minutes before the
        interval set above.
      </div>
      <div>
        Maximum inactivity time allowed before a missed welfare check is
        captured:
        <span class="fst-italic"> {{ selectedInterval }} minutes. </span>
      </div>
    </div>
    <div class="row row-cols-2 mt-4">
      <div class="col">
        <button
          class="btn btn-lg w-100 btn-dark"
          (click)="welfareIntervalTemplate.close()"
        >
          Cancel
        </button>
      </div>

      <div class="col">
        <button
          class="btn btn-primary btn-lg w-100"
          [disabled]="selectedInterval == detailData?.interval_check"
          (click)="updateInterval(selectedInterval)"
        >
          Confirm
        </button>
      </div>
    </div>
  </div>
</ng-template>

<!-- ADD COMMENT POPOVER / DIALOG -->
<ng-template #commentRef let-commentRef="dialogRef" let-data>
  <div class="card dialog-box">
    <div class="card-header">
      {{ data?.detail ? "Edit Comment" : "Add Comment" }}
      <span class="float-end" (click)="commentRef.close(); newComment = ''">
        <i class="fa-solid fa-xmark"></i>
      </span>
    </div>
    <div>
      <div class="form-group">
        <textarea
          class="form-control"
          rows="10"
          cols="32"
          wrap="hard"
          maxlength="340"
          type="text"
          placeholder="Start typing"
          [(ngModel)]="newComment"
        ></textarea>
      </div>
      <div class="row row-cols-2 mt-3">
        <div class="col">
          <button
            type="button"
            class="btn btn-dark btn-lg w-100"
            (click)="commentRef.close(); newComment = ''"
          >
            Cancel
          </button>
        </div>
        <div class="col">
          <button
            class="btn btn-primary btn-lg w-100"
            (click)="addComment(data); commentRef.close()"
          >
            {{ data?.detail ? "Update" : "Add" }}
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #requestRef let-requestRef="dialogRef">
  <div class="dialog-box card">
    <div class="card-header">
      Patrol Brief Information
      <span (click)="requestRef.close()" class="float-end"
        ><i class="fa-solid fa-xmark"></i
      ></span>
    </div>

    <!-- Request Detail Section start-->

    <div class="col">
      <textarea
        class="form-control"
        placeholder="Patrol Brief Information"
        cols="20"
        rows="5"
        wrap="hard"
        type="text"
        [(ngModel)]="newInfromation"
      ></textarea>
    </div>

    <div class="row row-cols-2 mt-4">
      <div class="col">
        <button class="btn btn-lg w-100 btn-dark" (click)="requestRef.close()">
          Cancel
        </button>
      </div>

      <div class="col">
        <button
          [disabled]="newInfromation === detailData?.patrol_brief"
          class="btn btn-lg w-100 btn-primary"
          (click)="updatePatrolRoute('patrol_brief'); requestRef.close()"
        >
          Update
        </button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #chekpointListTemplate let-chekpointListTemplate="dialogRef">
  <div class="dialog-box card">
    <div class="card-header">
      Checkpoints [{{ scannedCheckpointCount }} /
      {{ detailData?.check_points?.length }}]
      <span (click)="chekpointListTemplate.close()" class="float-end"
        ><i class="fa-solid fa-xmark"></i
      ></span>
    </div>
    <div class="overflow-cps">
      <div
        *ngFor="let item of detailData?.check_points; let i = index"
        class="d-flex mb-3"
      >
        <ng-template
          *ngTemplateOutlet="selectedCheckpointDetail; context: { value: item }"
        ></ng-template>
      </div>
    </div>
    <div
      class="row row-cols-2 mt-4"
      *ngIf="isAdmin === true || isDispatchUser === true"
    >
      <div class="col">
        <button
          class="btn btn-lg w-100 btn-dark"
          (click)="chekpointListTemplate.close()"
        >
          Cancel
        </button>
      </div>

      <div class="col">
        <button
          class="btn btn-lg w-100 btn-primary"
          (click)="
            openTemplate(updateCheckpointTemplate, 'checkpoint');
            chekpointListTemplate.close()
          "
        >
          Edit & Update
        </button>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #selectedCheckpointDetail let-item="value">
  <div *ngIf="!item?.isScanned" class="d-flex align-items-center">
    <a
      [href]="
        'https://www.google.com/maps/search/?api=1&query=' +
        item?.address?.latitude +
        ',' +
        item?.address?.longitude
      "
      target="_blank"
    >
      <span>
        <i class="fa-solid fa-location-arrow fa-2x"></i>
      </span>
    </a>
  </div>

  <div *ngIf="item?.isScanned" class="d-flex align-items-center">
    <span>
      <i class="fa-solid fa-check fa-2x text-success"></i>
    </span>
  </div>

  <div class="flex-grow-1 ms-3 me-2">
    <div class="d-flex align-items-center">
      <div>
        <div>{{ item.name }}</div>
        <div class="small">{{ item?.company_name }}</div>
      </div>
    </div>
    <div class="small-font text-secondary">
      {{ item?.address?.full_address }}
    </div>
  </div>

  <div *ngIf="item?.qr_code" class="me-3 cp-scanned-icon">
    <img
      src="assets/images/qr-code-logo.avif"
      alt=""
      width="26"
      class="mb-0 pb-0"
    />
  </div>
</ng-template>

<!-- checkpoint search/selection code to avoid code duplcation -->
<ng-template let-dataItem="value" #checkPointSearchSelectTemplate>
  <ng-container *ngIf="dataItem?.search">
    <div class="form-floating my-2" *ngIf="patrolRouteCpTotalCount > 10">
      <input
        class="form-control"
        type="text"
        placeholder="Filter Checkpoints"
        (input)="searchCheckPoints($event)"
      />
      <label>Filter Checkpoints</label>
    </div>
    <div class="overflow-cps">
      <table class="w-100">
        <tbody>
          <tr
            *ngIf="
              patrolRouteCpTotalPages === 1 && patrolRouteCPList?.length > 1
            "
          >
            <td>
              <div class="text-end">
                <button
                  (click)="addAllCheckpoints()"
                  class="btn btn-primary btn-sm m-0"
                >
                  Add All
                </button>
              </div>
            </td>
          </tr>
          <tr *ngFor="let checkpoint of patrolRouteCPList" class="pointer">
            <td>
              <div class="d-flex align-items-center">
                <div class="flex-grow-1">
                  {{ checkpoint?.name }} [{{ checkpoint?.company }}]
                  <div class="small text-secondary">
                    {{ checkpoint?.address?.full_address }}
                  </div>
                </div>
                <div *ngIf="checkpoint?.qr_code" class="me-2">
                  <img
                    src="assets/images/qr-code-logo.avif"
                    alt="QR Code"
                    width="26"
                    class="mb-0 pb-0"
                  />
                </div>

                <div (click)="addSelectedCP(checkpoint)">
                  <button class="btn btn-primary btn-sm m-0">Add</button>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div
      class="my-2"
      *ngIf="
        patrolRouteCpTotalPages > 1 &&
        patrolRouteCpTotalCount != patrolRouteCPList?.length
      "
    >
      <button
        type="button"
        class="btn btn-secondary btn-lg"
        (click)="onLoadMore()"
      >
        Load More
      </button>
    </div>
  </ng-container>
  <ng-container *ngIf="!dataItem?.search">
    <div class="overflow-cps">
      <table class="w-100">
        <tbody cdkDropList (cdkDropListDropped)="dropNewCheckPoint($event)">
          <tr
            *ngFor="let checkpoint of selectedCheckpoints; let i = index"
            class="pointer"
          >
            <td>
              <div class="d-flex align-items-center">
                <div class="me-2" cdkDrag>
                  <i class="fas fa-bars fa-2x"></i>
                </div>

                <div class="flex-grow-1">
                  {{ checkpoint?.name }} [{{
                    checkpoint?.company
                      ? checkpoint?.company
                      : checkpoint?.company_name
                  }}]
                  <div class="small text-secondary">
                    {{ checkpoint?.address?.full_address }}
                  </div>
                </div>
                <div *ngIf="checkpoint?.qr_code" class="me-2">
                  <img
                    src="assets/images/qr-code-logo.avif"
                    alt="QR Code"
                    width="26"
                    class="mb-0 pb-0"
                  />
                </div>
                <div (click)="removeSelectedCP(i)">
                  <button class="btn btn-danger btn-sm m-0">Del</button>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </ng-container>
</ng-template>
