<div class="p-2">
  <div *ngIf="!qrCodeList?.length">
    <div class="d-flex flex-column flex-md-row">
      <div class="flex-md-grow-1 mb-2 mb-md-0">
        <p>
          Intended for use with 63.5 x 72mm blank labels
          <a
            href="https://www.averyproducts.com.au/blank-labels/rectangle-72x63mm?mat=hdl"
            target="_blank"
            rel="noopener"
            class="fw-bold"
            >(L7164)</a
          >
        </p>
        <p>QR Codes must be deployed and registered at location</p>
      </div>
      <div class="ms-md-2 mb-2 mb-lg-0">
        <div class="row row-cols-1 gy-2">
          <div class="col">
            <button
              class="btn btn-primary btn-lg w-100"
              (click)="
                openTemplate(qrGenerateTemplateRef); existingQRCodes = false
              "
            >
              New QR Codes
            </button>
          </div>
          <div class="col">
            <button
              class="btn btn-secondary btn-lg w-100"
              (click)="
                openTemplate(existingQrGenerateTemplateRef); getQRCheckpoints()
              "
            >
              Reprint Existing Codes
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="qrCodeList?.length">
    <div class="row row-cols-auto">
      <div *ngFor="let qr of qrCodeList; let i = index" class="row m-auto">
        <qrcode
          style="display: none"
          [imageSrc]="qrImageSrc"
          [imageHeight]="350"
          [imageWidth]="350"
          [id]="qr?.qr_code + i"
          [qrdata]="qr?.qr_code"
          [ariaLabel]="qr?.qr_code"
          [colorDark]="'#000000ff'"
          [colorLight]="'#ffffffff'"
          [elementType]="'canvas'"
          [errorCorrectionLevel]="'H'"
          [margin]="1"
          [scale]="1"
          [width]="1200"
          (qrCodeURL)="onChangeURL($event, qr, qr?.qr_code + i)"
        ></qrcode>
      </div>
    </div>

    <div class="row mt-2">
      <div class="col-lg-4">
        <div class="alert alert-primary" role="alert">
          QR Codes Ready - QTY: {{ qrCodeList?.length }}
        </div>
      </div>
    </div>

    <div class="row mt-2">
      <div class="col-lg-4">
        <button
          class="btn btn-primary btn-lg btn-block"
          [disabled]="!(qrCodeList?.length === qrCodeb64ImageUrls?.length)"
          (click)="downloadQRCodes(1)"
        >
          Email Code(s)
        </button>
      </div>
    </div>
  </div>
</div>
<ng-template #qrGenerateTemplateRef let-qrGenerateTemplateRef="dialogRef">
  <div class="card dialog-box">
    <div class="card-header">
      Generate New QR Codes
      <span class="float-end" (click)="qrGenerateTemplateRef.close()">
        <i class="fa-solid fa-xmark"></i>
      </span>
    </div>
    <div>
      <select class="form-select" [(ngModel)]="qrCount">
        <option *ngFor="let no of [12, 24, 36]" [value]="no">
          Qty : {{ no }}
        </option>
      </select>
      <div class="row row-cols-2 mt-3">
        <div class="col">
          <button
            type="button"
            class="btn btn-dark btn-lg w-100"
            (click)="qrGenerateTemplateRef.close()"
          >
            Cancel
          </button>
        </div>
        <div class="col">
          <button
            class="btn btn-primary btn-lg w-100"
            (click)="generateNQRcodes(); qrGenerateTemplateRef.close()"
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template
  #existingQrGenerateTemplateRef
  let-existingQrGenerateTemplateRef="dialogRef"
>
  <div class="card dialog-box">
    <div class="card-header">
      Select Checkpoints
      <span class="float-end" (click)="existingQrGenerateTemplateRef.close()">
        <i class="fa-solid fa-xmark"></i>
      </span>
    </div>
    <div>
      <div class="form-floating mb-3">
        <input
          class="form-control"
          type="text"
          placeholder="Search"
          (input)="filterQRCheckpoints($event)"
        />
        <label>Search</label>
      </div>

      <table class="table">
        <tbody>
          <tr
            *ngFor="let checkpoint of qrCPList"
            (click)="addRemoveCheckpoint(checkpoint)"
            class="cursor-pointer"
          >
            <td>
              <input
                type="checkbox"
                class="form-check-input"
                [checked]="checkpoint?.selected"
              />
            </td>
            <td>
              {{ checkpoint?.name }}
              <div>
                @if(checkpoint?.site_name){
                {{ checkpoint?.site_name }} [ {{ checkpoint?.company }}] }@else
                {
                {{ checkpoint?.company }}
                }
              </div>
              <div class="small-font text-secondary">
                {{ checkpoint?.address?.full_address }}
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="row row-cols-2 mt-3">
        <div class="col">
          <button
            type="button"
            class="btn btn-dark btn-lg w-100"
            (click)="
              selectedQrCheckpoints = [];
              qrCPList = [];
              existingQrGenerateTemplateRef.close()
            "
          >
            Cancel
          </button>
        </div>
        <div class="col">
          <button
            class="btn btn-primary btn-lg w-100"
            [disabled]="!selectedQrCheckpoints?.length"
            (click)="generateExistingQRCodes()"
          >
            Next
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>
