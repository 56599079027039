<ul class="nav nav-tabs">
  <li class="nav-item" (click)="changeTab(uiTabs.tab1)">
    <a class="nav-link" [class.active]="activeTab === uiTabs.tab1">{{
      uiTabs.tab1
    }}</a>
  </li>
  <li class="nav-item" (click)="changeTab(uiTabs.tab2)">
    <a class="nav-link" [class.active]="activeTab === uiTabs.tab2">{{
      uiTabs.tab2
    }}</a>
  </li>
  <li class="nav-item" (click)="changeTab(uiTabs.tab3)">
    <a class="nav-link" [class.active]="activeTab === uiTabs.tab3">{{
      uiTabs.tab3
    }}</a>
  </li>
  <li
    class="nav-item"
    (click)="changeTab(uiTabs.tab4)"
    *ngIf="userPrompts?.length"
  >
    <a class="nav-link" [class.active]="activeTab === uiTabs.tab4">{{
      uiTabs.tab4
    }}</a>
  </li>
</ul>

<div class="tab-content" *ngIf="activeTab === uiTabs.tab1">
  <div class="tab-pane" [ngClass]="activeTab === uiTabs.tab1 ? 'active' : ''">
    <div
      class="row row-cols-1 row-cols-md-2 mt-2 gy-2 mb-2"
      *ngIf="!selectedEvent"
    >
      <div class="col col-md-10">
        <gtapp-auto-complete
          [data]="clientDropDownList"
          placeHolder="Search Clients"
          searchKeyword="company_name"
          [itemTemplate]="clientLookupTemplate"
          (inputChanged)="onClientSearch($event)"
          (selected)="onClientSelect($event)"
        >
        </gtapp-auto-complete>

        <ng-template #clientLookupTemplate let-item>
          <div>
            {{ item?.company_name }}
          </div>
        </ng-template>
      </div>

      <div class="col col-md-2">
        <button
          [disabled]="!(userEvents?.length && allowPDFExport)"
          class="btn btn-primary w-100 btn-lg"
          (click)="openTemplate(emailUserDialog)"
        >
          Email Report (.pdf)
        </button>
      </div>
    </div>

    <div>
      @if(largeView){
      <gtapp-gt-table
        [desktopTableBackendData]="userEvents"
        [desktopTableDetails]="userEventDetailsTableView"
        [tableStyle]="tableStyle"
        (rowClick)="onTableRowClick($event)"
        (sortColumn)="sortColumn($event)"
        (searchColumn)="searchColumn($event)"
        [otherParams]="otherParams"
        (paginationChange)="onChangePagination($event)"
      ></gtapp-gt-table
      >}@else {
      <gtapp-card-list
        *ngIf="!selectedEvent"
        [mobileCardBackendData]="userEvents"
        [mobileCardDetails]="userEventDetails"
        (rowClick)="onTableRowClick($event)"
        (sortColumn)="sortColumn($event)"
        (searchColumn)="searchColumn($event)"
        [otherParams]="otherParams"
        (paginationChange)="onChangePagination($event)"
      ></gtapp-card-list
      >}
    </div>
  </div>
</div>

<div class="tab-content">
  <div class="tab-pane" [ngClass]="activeTab === uiTabs.tab2 ? 'active' : ''">
    <div class="mt-2">
      <div
        class="row row-cols-1 row-cols-md-2 mt-2 gy-2 mb-2"
        *ngIf="!selectedEvent"
      >
        <div class="col col-md-10">
          <div class="d-flex justify-content-evenly align-items-center">
            <div *ngFor="let option of jobReportTypes">
              <div class="form-check">
                <input
                  id="{{ option }}"
                  class="form-check-input"
                  type="radio"
                  [value]="option"
                  (change)="jobReportSelectRadio($event)"
                  [checked]="option === selectedJobReportType"
                />
                <label class="form-check-label" for="{{ option }}">
                  {{ option }}
                </label>
              </div>
            </div>
          </div>
        </div>

        <div class="col col-md-2">
          <button
            (click)="mailBillingReport()"
            [disabled]="!(userEvents?.length && allowBillingReportExport)"
            class="btn btn-primary btn-lg w-100"
          >
            Email Report (*.xlsx)
          </button>
        </div>
      </div>

      <div>
        @if(largeView){
        <gtapp-gt-table
          [desktopTableBackendData]="userEvents"
          [desktopTableDetails]="userEventDetailsTableView"
          [tableStyle]="tableStyle"
          [otherParams]="{ paginationData: true }"
          (rowClick)="onJobTableRowClick($event)"
          (sortColumn)="sortColumn($event)"
          (searchColumn)="searchColumn($event)"
        ></gtapp-gt-table
        >}@else {
        <gtapp-card-list
          [mobileCardBackendData]="userEvents"
          [mobileCardDetails]="userEventDetails"
          (rowClick)="onJobTableRowClick($event)"
          (sortColumn)="sortColumn($event)"
          (searchColumn)="searchColumn($event)"
        ></gtapp-card-list
        >}
      </div>
    </div>
  </div>
</div>
<div class="tab-content">
  <div class="tab-pane" [ngClass]="activeTab === uiTabs.tab3 ? 'active' : ''">
    <div
      class="row row-cols-1 row-cols-md-2 mt-2 gy-2 mb-2"
      *ngIf="!selectedEvent"
    >
      <div class="col col-md-10">
        <gtapp-auto-complete
          [data]="clientDropDownList"
          placeHolder="Search Clients"
          searchKeyword="company_name"
          [itemTemplate]="clientLookupTemplate"
          (inputChanged)="onClientSearch($event)"
          (selected)="onClientSelect($event)"
        >
        </gtapp-auto-complete>

        <ng-template #clientLookupTemplate let-item>
          <div>
            {{ item?.company_name }}
          </div>
        </ng-template>
      </div>

      <div class="col col-md-2">
        <button
          [disabled]="!(userEvents?.length && allowPDFExport)"
          class="btn btn-primary w-100 btn-lg"
          (click)="exportData('pdf')"
        >
          Email Report (.pdf)
        </button>
      </div>
    </div>

    <div>
      @if(largeView){
      <gtapp-gt-table
        [desktopTableBackendData]="userEvents"
        [desktopTableDetails]="userEventDetailsTableView"
        [tableStyle]="tableStyle"
        (rowClick)="onTableRowClick($event)"
        (sortColumn)="sortColumn($event)"
        (searchColumn)="searchColumn($event)"
        [otherParams]="otherParams"
        (paginationChange)="onChangePagination($event)"
      ></gtapp-gt-table
      >}@else {
      <gtapp-card-list
        *ngIf="!selectedEvent"
        [mobileCardBackendData]="userEvents"
        [mobileCardDetails]="userEventDetails"
        (rowClick)="onTableRowClick($event)"
        (sortColumn)="sortColumn($event)"
        (searchColumn)="searchColumn($event)"
        [otherParams]="otherParams"
        (paginationChange)="onChangePagination($event)"
      ></gtapp-card-list
      >}
    </div>
  </div>
</div>

<div
  class="tab-content"
  *ngIf="activeTab === uiTabs.tab4 && userPrompts?.length"
>
  <div class="tab-pane" [ngClass]="activeTab === uiTabs.tab4 ? 'active' : ''">
    <div
      class="row row-cols-1 row-cols-md-2 mt-2 gy-2 mb-2"
      *ngIf="!selectedEvent"
    >
      <div class="col col-md-8">
        <gtapp-auto-complete
          [data]="clientDropDownList"
          placeHolder="Search Clients"
          searchKeyword="company_name"
          [itemTemplate]="clientLookupTemplate"
          (inputChanged)="onClientSearch($event)"
          (selected)="onClientSelect($event)"
        >
        </gtapp-auto-complete>

        <ng-template #clientLookupTemplate let-item>
          <div>
            {{ item?.company_name }}
          </div>
        </ng-template>
      </div>
      <div class="col col-md-2">
        <button
          [disabled]="!selectedClient?.id"
          class="btn btn-primary w-100 btn-lg"
          (click)="openTemplate(filterPromptDialog)"
        >
          User Prompt(s)
        </button>
      </div>

      <div class="col col-md-2">
        <button
          [disabled]="!(userEvents?.length && allowPDFExport)"
          class="btn btn-primary w-100 btn-lg"
          (click)="openTemplate(emailUserDialog)"
        >
          Email Report (*.xlsx)
        </button>
      </div>
    </div>

    <div>
      @if(largeView){
      <gtapp-gt-table
        [desktopTableBackendData]="userEvents"
        [desktopTableDetails]="userEventDetailsTableView"
        [tableStyle]="tableStyle"
        (rowClick)="onTableRowClick($event)"
        (sortColumn)="sortColumn($event)"
        (searchColumn)="searchColumn($event)"
        [otherParams]="otherParams"
        (paginationChange)="onChangePagination($event)"
      ></gtapp-gt-table
      >}@else {
      <gtapp-card-list
        *ngIf="!selectedEvent"
        [mobileCardBackendData]="userEvents"
        [mobileCardDetails]="userEventDetails"
        (rowClick)="onTableRowClick($event)"
        (sortColumn)="sortColumn($event)"
        (searchColumn)="searchColumn($event)"
        [otherParams]="otherParams"
        (paginationChange)="onChangePagination($event)"
      ></gtapp-card-list
      >}
    </div>
  </div>
</div>

<ng-container *ngIf="selectedEvent">
  <gtapp-selected-event-detail
    [selectedEvent]="selectedEvent"
    selectedEventFrom="patrol"
    (goBack)="hideDetails()"
    (editUserComment)="editComment($event, commentRef)"
  ></gtapp-selected-event-detail>
</ng-container>

<!-- Edit Comment Popover/Dialog -->
<ng-template #commentRef let-commentRef="dialogRef" let-data>
  <div class="card dialog-box">
    <div class="card-header">
      {{ data?.detail ? "Edit Comment" : "Add Comment" }}
      <span class="float-end" (click)="commentRef.close(); newComment = ''">
        <i class="fa-solid fa-xmark"></i>
      </span>
    </div>
    <div>
      <div class="form-group">
        <textarea
          class="form-control"
          rows="10"
          cols="32"
          wrap="hard"
          maxlength="340"
          type="text"
          placeholder="Start typing"
          [(ngModel)]="newComment"
        ></textarea>
      </div>
      <div class="row row-cols-2 mt-3">
        <div class="col">
          <button
            type="button"
            class="btn btn-dark btn-lg w-100"
            (click)="commentRef.close(); newComment = ''"
          >
            Cancel
          </button>
        </div>
        <div class="col">
          <button
            class="btn btn-primary btn-lg w-100"
            (click)="addComment(data); commentRef.close()"
          >
            {{ data?.detail ? "Update" : "Add" }}
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<!-- email billing report template -->
<!-- Report users dialog box -->
<ng-template #emailUserDialog let-uDRef="dialogRef">
  <div class="dialog-box card">
    <div class="card-header">
      Email Recipients
      <span class="float-end" (click)="uDRef.close()">
        <i class="fa-solid fa-xmark"></i>
      </span>
    </div>

    <div class="row row-cols-auto gy-2 mb-3">
      <div *ngFor="let user of selectedUsers">
        <button class="btn btn-dark rounded-5 small btn-sm">
          {{ user?.name }}
          <span class="ms-4" (click)="removeUser(user)">
            <i class="fa-solid fa-xmark"></i>
          </span>
        </button>
      </div>
    </div>
    <div class="mb-3">
      <gtapp-auto-complete
        [data]="usersList"
        placeHolder="Email Recipients"
        searchKeyword="user_full_name"
        [itemTemplate]="reportContactTemplate"
        [addInitialKey]="true"
        (inputChanged)="getUsers($event)"
        (selected)="addUser($event?.pk, $event)"
      >
      </gtapp-auto-complete>
    </div>

    <ng-template #reportContactTemplate let-item>
      @if (item?.key === 0) {
      <div>Add New Contact</div>
      } @else {
      <div>
        {{ item?.user_full_name ? item.user_full_name : item.user_email }}
        <div>
          <small>
            {{ item?.user_full_name ? item.user_email : "" }}
            {{
              item?.user_mobile_number ? " , " + item?.user_mobile_number : ""
            }}
          </small>
        </div>
      </div>
      }
    </ng-template>

    <div class="row row-cols-2 mt-3">
      <div class="col">
        <button class="btn btn-lg w-100 btn-dark" (click)="uDRef.close()">
          Cancel
        </button>
      </div>
      <div class="col">
        <button
          class="btn btn-lg w-100 btn-primary"
          [disabled]="!selectedUsers?.length"
          (click)="
            exportData(selectedPromptIds?.length ? 'pdf' : 'csv'); uDRef.close()
          "
        >
          Send
        </button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #filterPromptDialog let-filterPromptDialog="dialogRef">
  <div class="dialog-box card">
    <div class="card-header">
      Prompts
      <span class="float-end" (click)="filterPromptDialog.close()">
        <i class="fa-solid fa-xmark"></i>
      </span>
    </div>

    <ng-container>
      <table class="table">
        <tbody>
          <tr
            *ngFor="let prompt of userPrompts; let i = index"
            class="cursor-pointer border-bottom"
            (click)="prompt.selected = !prompt?.selected"
          >
            <td class="">
              <input
                type="checkbox"
                class="form-check-input"
                [checked]="prompt?.selected"
              />
            </td>

            <td>
              {{ prompt?.name }}
            </td>
          </tr>
        </tbody>
      </table>

      <div class="row row-cols-2 mt-3">
        <div class="col">
          <button
            class="btn btn-dark w-100 btn-lg"
            (click)="filterPromptDialog.close()"
          >
            Cancel
          </button>
        </div>

        <div class="col">
          <button
            type="button"
            [disabled]="!selectedPromptIds?.length"
            class="btn btn-primary w-100 btn-lg"
            (click)="searchData(); filterPromptDialog.close()"
          >
            Search
          </button>
        </div>
      </div>
    </ng-container>
  </div>
</ng-template>
