import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppService } from '../../../app.service';
import { AddEditClientComponent } from '../../../shared/components/add-edit-client/add-edit-client.component';
import { ConfirmDialogComponent } from '../../../shared/components/confirm-dialog/confirm-dialog.component';
import { ModelDialogueService } from '../../../shared/components/modal-dialogue/model-dialogue.service';
import { DataCheckService } from '../../../shared/services/data-check.service';
import { LoadingSpinnerService } from '../../../shared/services/loading-spinner.service';
import { ToasterService } from '../../../shared/services/toaster.service';
import { ClientsService } from './clients.service';
import { FileImportComponent } from './file-import/file-import.component';
@Component({
  selector: 'gtapp-clients',
  templateUrl: './clients.component.html',
  styleUrl: './clients.component.scss',
})
export class ClientsComponent implements OnInit {
  globalFilter: any;
  tableStyle = {
    'overflow': 'auto',
    'max-height': '100%',
  };

  userData: any;
  clientCount: number = 0;
  previous: number = 0;
  pageSize: number = 10;
  pageNum: number = 1;
  totalPages: number = 0;
  clientPaginationData: any = {};
  fileImportInProgress: any;
  totalClientCount: number = 0;
  addReportUsers: boolean = false;

  archivedCount: any;
  showArchivedList: boolean = false;

  sortKey: any;

  clientDetailsTableView: any = [
    {
      header: 'Name',
      name: 'company_name',
      sortKey: 'company_name',
      searchKey: 'company_name',
    },
    {
      header: 'Address',
      nestedValue: (row: any) => {
        return row?.address?.full_address;
      },
    },
  ];
  otherParams: any;
  searchParams: any;
  isAdmin: any;
  clientDropDownList: any = [];

  tabNames = { tab1: 'Sites', tab2: 'Clients' };
  selectedTab = this.tabNames.tab1;

  constructor(
    private clientService: ClientsService,
    private spinnerService: LoadingSpinnerService,
    private _location: Location,
    private appService: AppService,
    private router: Router,
    private route: ActivatedRoute,
    private toasterService: ToasterService,
    private dataCheckService: DataCheckService,
    private dialogService: ModelDialogueService
  ) {
    this.route.params.subscribe({
      next: (params) => {
        this.addReportUsers = false;
        if (this.route.snapshot.fragment) {
          if (this.route.snapshot.fragment === 'addReportUsers') {
            this.addReportUsers = true;
          }
        }
      },
    });
  }

  ngOnInit(): void {
    this.userData = this.appService.getUserData();
    this.isAdmin = this.dataCheckService.isUserAdmin();
    this.pageSize = Number(this.userData?.preferences?.client_page_size || 10);
    this.spinnerService.show();
  }

  fetchCompanyList() {
    let params: any = { extra_details: 1 };

    if (this.pageSize) {
      params['rows'] = this.pageSize;
      let userData = this.appService.getUserData();
      if (userData?.preferences) {
        userData.preferences.client_page_size = this.pageSize;
        this.appService.setUserData(userData);
      }
    }
    if (this.previous) {
      params['previous'] = this.previous;
    }

    if (this.globalFilter) {
      params['search_str'] = this.globalFilter;
    }
    if (this.sortKey) {
      params['sort_key'] = this.sortKey;
    }
    if (this.addReportUsers) {
      params['report_users'] = 1;
    }
    if (this.showArchivedList) {
      params['is_inactive'] = 1;
    }
    if (this.searchParams) {
      Object.entries(this.searchParams).forEach(
        ([key, value]) => (params[key] = value)
      );
    }

    if (
      !this.clientPaginationData?.[this.pageNum]?.length ||
      this.globalFilter
    ) {
      this.spinnerService.show();
      this.clientService.fetchCustomerList(params).subscribe((res: any) => {
        if (res['status'] == 'success') {
          this.clientCount = res['total_size'];
          this.archivedCount = res?.archived_count || 0;

          this.totalClientCount = res?.['company_count'] || 0;
          if (this.totalClientCount === 0) {
            this.openAddClientForm();
          }
          this.totalPages = Math.ceil(this.clientCount / this.pageSize);

          this.clientPaginationData[this.pageNum] = [...res?.data];
          this.fileImportInProgress = res?.file_import;

          this.spinnerService.hide();
          this.otherParams = {
            paginationData: {
              totalRows: this.clientCount,
              previousRows: this.previous,
              rows: this.pageSize,
              pageNum: this.pageNum,
              totalPages: this.totalPages,
            },
          };
        } else {
          this.spinnerService.hide();
        }
      });
    }
  }
  onClientLookup(event: any) {
    if (
      event.target.value.length === 3 ||
      (event.target.value.length > 3 && this.clientDropDownList?.length)
    ) {
      let params: any = { search_str: event.target.value };
      if (this.showArchivedList) {
        params['is_inactive'] = 1;
      }
      this.clientService.fetchCustomerList(params).subscribe((res: any) => {
        if (res['status'] == 'success') {
          this.clientDropDownList = res?.data;
        }
      });
    }
  }

  openAddClientForm() {
    const dialogueRef = this.dialogService.open(AddEditClientComponent);
    dialogueRef.afterClosed().subscribe((value: any) => {
      if (value !== false) {
        this.clientPaginationData = {};
        this.fetchCompanyList();
      }
    });
  }

  openCustomerView(customer: any) {
    if (this.showArchivedList) {
      this.archiveUnarchive(customer);
    } else {
      this.router.navigate(['/view-client', { cKey: String(customer.id) }]);
    }
  }

  onChangeTablePagination(data: any) {
    this.previous = data?.paginationData?.previousRows;
    this.pageNum = data?.paginationData?.pageNum;
    if (this.pageSize != data?.paginationData?.rows) {
      this.clientPaginationData = {};
    }
    this.pageSize = data?.paginationData?.rows;

    this.fetchCompanyList();

    this.otherParams = {
      paginationData: { ...data?.paginationData },
    };
  }

  openUploadDialogBox() {
    const fileImportDialogRef = this.dialogService.open(
      FileImportComponent,
      {}
    );
    fileImportDialogRef.afterClosed().subscribe((value) => {
      if (value !== 'close') {
        window.location.reload();
      }
    });
  }

  getArchivedUnarchivedList() {
    this.showArchivedList = !this.showArchivedList;
    this.clearValues();

    this.spinnerService.show();
    this.fetchCompanyList();
  }
  sortColumn(body: any) {
    this.sortKey = body?.sortList;
    this.clearValues();
    this.fetchCompanyList();
  }
  searchColumn(event: any) {
    this.clearValues();

    this.searchParams = event?.searchParams;

    this.fetchCompanyList();
  }
  clearValues() {
    this.previous = 0;
    this.pageNum = 1;
    this.globalFilter = '';
    this.clientPaginationData = {};
  }
  archiveUnarchive(client: any) {
    const dialogRef = this.dialogService.open(ConfirmDialogComponent, {
      data: {
        title: `Restore  Client `,
        message: ``,
      },
    });
    dialogRef.afterClosed().subscribe((value) => {
      if (value === true) {
        this.spinnerService.show();
        this.clientService
          .enableDisableCompanyById(client?.id)
          .then((response: any) => {
            this.spinnerService.hide();
            if (response?.status == 'success') {
              this.toasterService.setMessage({
                successMessage: response['message'],
                errorMessage: '',
              });
              this.router.navigate([
                '/view-client',
                { cKey: String(client.id) },
              ]);
            } else {
              this.toasterService.setMessage({
                successMessage: '',
                errorMessage: response['message'],
              });
            }
          });
      }
    });
  }
}
