import {
  CdkDragDrop,
  CdkDragEnd,
  CdkDragStart,
  moveItemInArray,
  transferArrayItem,
} from '@angular/cdk/drag-drop';
import { Location } from '@angular/common';
import {
  Component,
  NgZone,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmDialogComponent } from '../../../../shared/components/confirm-dialog/confirm-dialog.component';
import { ModelDialogueService } from '../../../../shared/components/modal-dialogue/model-dialogue.service';
import { PrompterComponent } from '../../../../shared/components/prompter/prompter.component';
import { ToasterService } from '../../../../shared/services/toaster.service';
import { UserPromptService } from '../user-prompt.service';
import { LoadingSpinnerService } from '../../../../shared/services/loading-spinner.service';

@Component({
  selector: 'gtapp-add-edit-user-prompt',
  templateUrl: './add-edit-user-prompt.component.html',
  styleUrl: './add-edit-user-prompt.component.scss',
})
export class AddEditUserPromptComponent {
  dialogRef: any;
  userPromptId: any;
  userPromptDetailedData: any;
  addPromptOptions: any = [
    { promptLabel: 'Show Message', value: 'note' },
    // { promptLabel: 'Followup', value: 'radio' },
    { promptLabel: 'Text Field', value: 'text' },

    { promptLabel: 'Yes / No', value: 'singleChoice' },
    { promptLabel: 'Multiple Choice', value: 'checkbox' },
  ];
  fieldProperties: any = {
    note: {
      type: 'output',
      field: 'note',
      description: '',
    },
    text: {
      type: 'input',
      field: 'text',
      description: '',
      value: '',
      required: true,
      showInReports: true,
    },
    checkbox: {
      type: 'input',
      field: 'checkbox',
      description: '',
      required: true,
      showInReports: true,
      allowedSelections: 1,
      value: [],
      options: [],
    },
    singleChoice: {
      type: 'input',
      field: 'checkbox',
      singleChoice: 1,
      description: '',
      required: true,
      showInReports: true,
      allowedSelections: 1,
      value: [],
      options: [{ key: 'Yes' }, { key: 'No' }],
    },
  };
  paginatedPromptData: any = [];
  selectedPagePrompts: any = [];
  page: number = 0;
  showOnHome: boolean = false;
  tempOption: string = '';
  promptName: string = '';
  checkPointTriggers: any = [];
  constructor(
    private userPromptService: UserPromptService,
    private router: Router,
    private route: ActivatedRoute,
    private dialogService: ModelDialogueService,
    private location: Location,
    private ngZone: NgZone,
    private viewContainerRef: ViewContainerRef,
    private toasterService: ToasterService,
    private spinnerService: LoadingSpinnerService
  ) {
    // WHEN USER CLICK THE BACK BUTTON
    router.events.subscribe((event: any) => {
      if (event.navigationTrigger === 'popstate') {
        this.dialogRef?.close();
      }
    });
    this.route.params.subscribe({
      next: (params) => {
        if (params?.['uPKey']) {
          this.userPromptId = params?.['uPKey'];
          this.getUserPromptDetail();
        }
      },
    });
  }

  ngOnInit(): void {}
  getUserPromptDetail() {
    this.userPromptService
      .getUserPromptById(this.userPromptId)
      .subscribe((response: any) => {
        if (response['status'] == 'success') {
          this.userPromptDetailedData = response?.data;
          this.checkPointTriggers = response?.triggers || [];
          this.promptName = this.userPromptDetailedData?.name;
          this.paginatedPromptData = [...this.userPromptDetailedData?.prompts];
          this.selectedPagePrompts = this.paginatedPromptData[this.page];
          this.showOnHome = this.userPromptDetailedData?.show_on_home;
        }
      });
  }
  viewCheckpointDetail(checkpoint: any) {
    this.router.navigate([
      '/view-checkpoints',
      { cpKey: String(checkpoint?.id) },
    ]);
  }
  openTemplate(template: TemplateRef<any>, field?: any, opt?: any) {
    this.dialogRef = this.dialogService.open(
      template,
      {
        data: { field: field, option: opt },
      },
      this.viewContainerRef
    );
  }
  changePagination(next = true) {
    this.page = next ? this.page + 1 : this.page - 1;
    if (this.page <= this.paginatedPromptData?.length)
      this.selectedPagePrompts = this.paginatedPromptData[this.page];
  }
  addItem(itemData: any, subOptionSelect?: any) {
    if (itemData == 'page') {
      if (this.selectedPagePrompts?.length) {
        this.page += 1;
        this.paginatedPromptData[this.page] = [];
        this.selectedPagePrompts = [];
      }
    } else {
      const fieldPropertiesBackup = JSON.parse(
        JSON.stringify(this.fieldProperties)
      );
      let selectedProperty = fieldPropertiesBackup[itemData?.value];
      selectedProperty.promptKeyValue = itemData?.value;
      if (subOptionSelect?.key) {
        const existingPagePromts: any = subOptionSelect.selectSubOptions || [];
        existingPagePromts.push(selectedProperty);
        subOptionSelect.selectSubOptions = existingPagePromts;
      } else {
        const existingPagePromts: any =
          this.paginatedPromptData[this.page] || [];
        existingPagePromts.push(selectedProperty);
        this.paginatedPromptData[this.page] = existingPagePromts;
        this.paginatedPromptData = [...this.paginatedPromptData];
        this.selectedPagePrompts = [...this.paginatedPromptData[this.page]];
      }
    }
  }

  removeCurrentPage() {
    const dialogRef = this.dialogService.open(ConfirmDialogComponent, {
      data: {
        title: 'Are you sure?',
        message: ``,
      },
    });
    dialogRef.afterClosed().subscribe((value: any) => {
      if (value === true) {
        this.paginatedPromptData?.splice(this.page, 1);

        this.page = this.page === 0 ? 0 : this.page - 1;
        this.selectedPagePrompts = [...this.paginatedPromptData[this.page]];
      }
    });
  }
  updatePage() {
    this.paginatedPromptData = [...this.paginatedPromptData];
    this.selectedPagePrompts = [...this.paginatedPromptData[this.page]];
  }
  removeItem() {
    this.paginatedPromptData[this.page] = this.selectedPagePrompts;
    this.paginatedPromptData = [...this.paginatedPromptData];
    this.selectedPagePrompts = [...this.paginatedPromptData[this.page]];
  }
  previewPrompts() {
    this.dialogRef = this.dialogService.open(PrompterComponent, {
      data: { promptData: this.paginatedPromptData, preview: true },
    });
  }

  savePrompts() {
    const body: any = {
      name: this.promptName,
      prompts: this.paginatedPromptData,
      show_on_home: this.showOnHome,
    };
    if (this.userPromptDetailedData?.id) {
      this.userPromptService
        .updateUserPrompt(this.userPromptDetailedData?.id, body)
        .subscribe((response: any) => {
          if (response?.status === 'success') {
            this.toasterService.setMessage({
              successMessage: response?.message,
              errorMessage: '',
            });
            this.goBack();
          } else {
            this.toasterService.setMessage({
              successMessage: '',
              errorMessage: response?.message,
            });
          }
        });
    } else {
      this.userPromptService
        .createUserPrompt(body)
        .subscribe((response: any) => {
          if (response?.status === 'success') {
            this.toasterService.setMessage({
              successMessage: response?.message,
              errorMessage: '',
            });
            this.goBack();
          } else {
            this.toasterService.setMessage({
              successMessage: '',
              errorMessage: response?.message,
            });
          }
        });
    }
  }
  goBack() {
    this.location.back();
  }
  disabeSaveButton(): boolean {
    if (!this.paginatedPromptData?.length) {
      return true;
    }
    if (!this.promptName) {
      return true;
    }
    function processObject(obj: any): boolean {
      if (!obj.description) return true;
      if (
        obj?.field === 'checkbox' &&
        !obj?.singleChoice &&
        obj.options?.length < 2
      )
        return true;
      else {
        if (obj.value?.length && obj?.options?.length) {
          for (const subOption of obj.options) {
            if (
              subOption?.selectSubOptions?.length &&
              subOption?.key === obj?.value
            ) {
              for (const item of subOption?.selectSubOptions) {
                const result = processObject(item);
                if (result) {
                  return result;
                }
              }
            }
          }
        }
      }

      return false;
    }

    for (const pagePrompt of this.paginatedPromptData) {
      if (!pagePrompt?.length) return true;
      for (const item of pagePrompt) {
        const result = processObject(item);
        if (result) {
          return result;
        }
      }
    }
    return false;
  }
  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    }
  }
  maxSelectionsAllowedValidation(event: any, fieldData: any) {
    const fieldChoicesAvailable = fieldData?.options?.length || 1;

    setTimeout(() => {
      this.ngZone.run(() => {
        fieldData.allowedSelections =
          Math.min(event.target.value, fieldChoicesAvailable) ||
          fieldChoicesAvailable;
      });
    }, 1000);
  }
  yesNoPresent(fieldData: any) {
    return fieldData?.options?.some((choice: any) =>
      ['yes', 'no'].includes(choice?.key?.toLowerCase())
    );
  }
  deleteSection(arrayList: any, index: number) {
    const dialogRef = this.dialogService.open(ConfirmDialogComponent, {
      data: {
        title: 'Are you sure?',
        message: ``,
      },
    });
    dialogRef.afterClosed().subscribe((value: any) => {
      if (value === true) {
        arrayList.splice(index, 1);
        this.removeItem();
      }
    });
  }
  copyPrompt() {
    const dialogRef = this.dialogService.open(ConfirmDialogComponent, {
      data: {
        title: 'Are you sure?',
        message: `Copy and save this as New Prompt!`,
        alertStatus: 'info',
      },
    });
    dialogRef.afterClosed().subscribe((value: any) => {
      if (value === true) {
        this.spinnerService.show();
        const body: any = {
          name: this.promptName,
          prompts: this.paginatedPromptData,
        };
        this.userPromptService
          .createUserPrompt(body)
          .subscribe((response: any) => {
            this.spinnerService.hide();
            if (response?.status === 'success') {
              this.toasterService.setMessage({
                successMessage: response?.message,
                errorMessage: '',
              });
              this.goBack();
            } else {
              this.toasterService.setMessage({
                successMessage: '',
                errorMessage: response?.message,
              });
            }
            this.spinnerService.hide();
          });
      }
    });
  }
  dragging = false;

  onDragStarted(event: CdkDragStart): void {
    this.dragging = true;
  }

  onDragEnded(event: CdkDragEnd): void {
    this.dragging = false;
  }

  dropSection(event: CdkDragDrop<string[]>): void {
    moveItemInArray(
      this.selectedPagePrompts,
      event.previousIndex,
      event.currentIndex
    );
  }
  moveSection(currentIndex: number, newIndex: any): void {
    newIndex = newIndex?.target?.value;
    const index = Number(newIndex);
    if (index >= 0 && index < this.selectedPagePrompts.length) {
      moveItemInArray(this.selectedPagePrompts, currentIndex, index);
    }
  }
}
