<div
  class="text-center fw-bold"
  *ngIf="
    ['patrol', 'guard_last_events'].includes(selectedEventType) &&
    !selectedEvent
  "
>
  {{ eventTitle }}
</div>

@if(largeView){
<gtapp-gt-table
  [desktopTableBackendData]="userEvents"
  [desktopTableDetails]="userEventDetailsTableView"
  [tableStyle]="tableStyle"
  (rowClick)="onTableRowClick($event)"
  (sortColumn)="sortColumn($event)"
  (searchColumn)="searchColumn($event)"
  [otherParams]="otherParams"
  (paginationChange)="onPaginationChange($event)"
></gtapp-gt-table>

}@else {
<!-- <gtapp-card-list
  *ngIf="!selectedEvent"
  [mobileCardBackendData]="userEvents"
  [mobileCardDetails]="userEventDetailsMobileView"
  (rowClick)="onTableRowClick($event)"
  (sortColumn)="sortColumn($event)"
  (searchColumn)="searchColumn($event)"
  [otherParams]="otherParams"
  (paginationChange)="onPaginationChange($event)"
></gtapp-card-list> -->

<gtapp-infinite-list
  *ngIf="!selectedEvent"
  [mobileCardBackendData]="infiniteDataList"
  [mobileCardDetails]="userEventDetailsMobileView"
  [totalRows]="totalRows"
  (loadMoreData)="previous = previous + pageSize; getUserUpdateLog()"
  (rowClick)="onTableRowClick($event)"
  (sortColumn)="sortColumn($event)"
  (searchColumn)="searchColumn($event)"
></gtapp-infinite-list
>}

<ng-container *ngIf="selectedEvent">
  <gtapp-selected-event-detail
    [selectedEvent]="selectedEvent"
    [selectedEventFrom]="selectedEventType"
    [userEventDetailsTableView]="userEventDetailsTableView"
    (goBack)="hideDetails()"
    (editUserComment)="editComment($event, commentRef)"
  ></gtapp-selected-event-detail>
</ng-container>

<!-- Edit Comment Popover/Dialog -->
<ng-template #commentRef let-commentRef="dialogRef" let-data>
  <div class="card dialog-box">
    <div class="card-header">
      {{ data?.detail ? "Edit Comment" : "Add Comment" }}
      <span class="float-end" (click)="commentRef.close(); newComment = ''">
        <i class="fa-solid fa-xmark"></i>
      </span>
    </div>
    <div>
      <div class="form-group">
        <textarea
          class="form-control"
          rows="10"
          cols="32"
          wrap="hard"
          maxlength="340"
          type="text"
          placeholder="Start typing"
          [(ngModel)]="newComment"
        ></textarea>
      </div>
      <div class="row row-cols-2 mt-3">
        <div class="col">
          <button
            type="button"
            class="btn btn-dark btn-lg w-100"
            (click)="commentRef.close(); newComment = ''"
          >
            Cancel
          </button>
        </div>
        <div class="col">
          <button
            class="btn btn-primary btn-lg w-100"
            (click)="addComment(data); commentRef.close()"
          >
            {{ data?.detail ? "Update" : "Add" }}
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>
