<div
  class="row row-cols-1 row-cols-lg-2 d-flex"
  [ngStyle]="{ height: containerHeight }"
>
  <ng-container *ngIf="!selectedUser?.id || largeView">
    <div class="col col-lg-4 d-flex flex-column">
      <div class="top-section">
        <div
          *ngFor="let user of userMessageList"
          (click)="getUserMessage(user); user.unread = 0"
        >
          <div class="d-flex gt-card mb-3 cursor-pointer">
            <div class="flex-grow-1">
              {{ user?.full_name }}
            </div>
            <div *ngIf="user?.unread" class="badge bg-danger">
              {{ user?.unread }}
            </div>
          </div>
        </div>
        <div class="mt-1 mx-1"></div>
      </div>

      <button
        class="btn btn-lg w-100 btn-primary rounded-0"
        (click)="openTemplate(guardTemplate)"
      >
        + New Chat
      </button>
    </div>
  </ng-container>
  <ng-container *ngIf="selectedUser?.id">
    <div class="col col-lg-8 d-flex flex-column">
      <div
        #content
        class="top-section fixed-message-height"
        [ngStyle]="{ 'max-height': messageFixedHeight }"
      >
        <div class="fw-bold text-center">{{ selectedUser?.full_name }}</div>
        <div
          #messages
          *ngFor="let message of messageList"
          class="mb-2"
          [ngClass]="message?.sender?.id == subscriberUserId ? '' : 'receiver'"
        >
          <div class="w-75 message-card">
            <div class="small-font">
              {{ message?.created_at | customDate : "d MMM y HH:mm" }}
              <span *ngIf="message?.event_key">
                | {{ message?.event_key }}</span
              >
            </div>
            <div>
              {{ message?.message }}
            </div>
            <img
              class="zoomImage"
              (click)="onOpenImage($event, message)"
              *ngIf="message?.file"
              src="{{ message?.file }}"
              height="120"
              width="120"
            />
            <div
              class="small-font text-end"
              *ngIf="
                message?.sender?.id == subscriberUserId && message?.read_at
              "
            >
              Read at : {{ message?.read_at | customDate : "d MMM y HH:mm" }}
            </div>
          </div>
        </div>
      </div>
      <div id="bottom-btns">
        <div class="d-flex">
          <div class="flex-grow-1">
            <!-- <div
          *ngIf="selectedJobData?.event_id"
          (click)="selectedJobData = {}"
          style="background-color: bisque; font-size: 0.6rem; font-weight: 800"
        >
          Link : {{ selectedJobData?.job_id }}
          <div class="sc-floating-right">x</div>
        </div> -->
            <div class="form-floating">
              <input
                class="form-control"
                type="text"
                [(ngModel)]="chatMessage"
                placeholder="Message"
                (focus)="hideAdditionalItems = true"
                (blur)="hideAdditionalItems = false"
                (keyup.enter)="chatMessage ? sendMessage() : null"
              />
              <label>Message</label>
            </div>
          </div>
          <!-- <div class="ms-1" *ngIf="jobListData?.length">
        <button
          style="height: 100%"
          nbButton
          size="medium"
          fullWidth
          status="info"
          (click)="linkJob(jobTemplate)"
        >
          @
        </button>
      </div> -->
          <div class="ms-1" *ngIf="!hideAdditionalItems">
            <button
              class="btn btn-primary rounded-0 btn-lg"
              (click)="openCamPopupDialog()"
            >
              <i class="fa-regular fa-file fa-2x"></i>
            </button>
          </div>
          <div class="ms-1">
            <button
              class="btn btn-primary rounded-0 btn-lg"
              [disabled]="!chatMessage"
              (click)="sendMessage()"
            >
              <i class="fa fa-paper-plane fa-2x"></i>
            </button>
          </div>
        </div>
        <div class="pt-2" *ngIf="!largeView">
          <button
            type="button"
            class="btn btn-lg btn-dark w-100"
            (click)="goBack()"
          >
            Back
          </button>
        </div>
      </div>
    </div>
  </ng-container>
</div>

<ng-template #guardTemplate let-data let-guardTemplate="dialogRef">
  <div class="dialog-box card">
    <div class="card-header">
      Select Guard
      <span class="float-end" (click)="guardTemplate.close()">
        <i class="fa-solid fa-xmark"></i>
      </span>
    </div>
    <div class="mb-2">
      <gtapp-auto-complete
        [data]="assigneeSearchList"
        placeHolder="Search Guards"
        searchKeyword="full_name"
        [itemTemplate]="guardTemplate"
        (inputChanged)="searchAssignee($event)"
      >
      </gtapp-auto-complete>
      <ng-template #guardTemplate let-item>
        <div>{{ item?.full_name }}</div>
      </ng-template>
    </div>
    <table class="table">
      <tbody>
        <tr *ngFor="let assignee of assigneeList" class="pointer">
          <td
            (click)="
              getUserMessage(assignee);
              guardTemplate.close();
              newChat = true;
              messageList = []
            "
          >
            {{ assignee?.full_name }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</ng-template>
