<div>
  <ul class="nav nav-tabs" id="myTab" role="tablist">
    <li
      class="nav-item"
      role="presentation"
      (click)="changeTab(licenseTab.tab1)"
    >
      <a
        class="nav-link"
        [ngClass]="selectedTab === licenseTab.tab1 ? 'active' : ''"
        >{{ licenseTab.tab1 }}</a
      >
    </li>
    <li
      class="nav-item"
      role="presentation"
      (click)="changeTab(licenseTab.tab2)"
    >
      <a
        class="nav-link"
        [ngClass]="selectedTab === licenseTab.tab2 ? 'active' : ''"
        >{{ licenseTab.tab2 }}</a
      >
    </li>
  </ul>
  <div class="tab-content" id="myTabContent">
    <div
      class="tab-pane"
      [ngClass]="selectedTab === licenseTab.tab1 ? 'active' : ''"
    >
      <div
        *ngIf="userDataList && !hasKeys(userDataList[0])"
        class="alert alert-primary"
        role="alert"
      >
        No licenses have been added by any Guards.
      </div>
      <div *ngIf="showChart && userDataList[0] && hasKeys(userDataList[0])">
        <!-- <highcharts-chart
            [Highcharts]="highChartComponent"
            [options]="pieChartOptions"
            style="width: 100%; height: 400px; display: block"
          ></highcharts-chart> -->
      </div>
      <div class="mt-4">
        {{ selectedItem }}
      </div>
    </div>
    <div
      class="tab-pane"
      [ngClass]="selectedTab === licenseTab.tab2 ? 'active' : ''"
    >
      <div>
        <div
          *ngIf="userDataList && !hasKeys(userDataList[1])"
          class="alert alert-primary"
          role="alert"
        >
          No Users without License.
        </div>
        <div class="mt-4" *ngIf="userDataList && hasKeys(userDataList[1])">
          <gtapp-card-list
            class="d-block d-md-none"
            [mobileCardBackendData]="userDataList?.[1]?.users"
            [mobileCardDetails]="unLicnesedGuardDetails"
            (rowClick)="openGuardInfo($event)"
          ></gtapp-card-list>
          <gtapp-gt-table
            class="d-none d-md-block"
            [desktopTableUIData]="userDataList?.[1]?.users"
            [desktopTableDetails]="unLicnesedGuardTableDetails"
            (rowClick)="openGuardInfo($event)"
          ></gtapp-gt-table>
        </div>
      </div>
    </div>
  </div>
</div>
