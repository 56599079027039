<ul class="nav nav-tabs mb-2">
  <li class="nav-item" (click)="selectedTab = tabTitles.tab1">
    <a
      class="nav-link"
      data-toggle="tab"
      [ngClass]="selectedTab === tabTitles.tab1 ? 'active' : ''"
      >{{ tabTitles.tab1 }}</a
    >
  </li>
  <li class="nav-item" (click)="selectedTab = tabTitles.tab2">
    <a
      class="nav-link"
      data-toggle="tab"
      [ngClass]="selectedTab === tabTitles.tab2 ? 'active' : ''"
      >{{ tabTitles.tab2 }}</a
    >
  </li>
</ul>
<div class="tab-content">
  <div
    role="tabpanel"
    class="tab-pane"
    id="tab1"
    [ngClass]="selectedTab === tabTitles.tab1 ? 'active' : ''"
  >
    <div *ngIf="!largeView">
      <gtapp-card-list
        [mobileCardBackendData]="
          selectedMissedWelfare ? [selectedMissedWelfare] : missedWelfareData
        "
        [mobileCardDetails]="mobileDetailMissedWelfareCheck"
        [otherParams]="
          selectedMissedWelfare ? {} : otherParamsMissedWelfareCheck
        "
        (searchColumn)="searchColumnMissedWelfareCheck($event)"
        (sortColumn)="sortColumnMissedWelfareCheck($event)"
        (paginationChange)="onChangePaginationMissedWelfareCheck($event)"
        (rowClick)="selectedMissedWelfare = $event"
        [showCallGuardButton]="true"
      ></gtapp-card-list>
    </div>
    <div *ngIf="largeView">
      <gtapp-gt-table
        [desktopTableBackendData]="missedWelfareData"
        [desktopTableDetails]="tableDetailMissedWelfareCheck"
        [otherParams]="otherParamsMissedWelfareCheck"
        (searchColumn)="searchColumnMissedWelfareCheck($event)"
        (sortColumn)="sortColumnMissedWelfareCheck($event)"
        (paginationChange)="onChangePaginationMissedWelfareCheck($event)"
        (rowClick)="selectedMissedWelfare = $event"
      >
      </gtapp-gt-table>
    </div>

    <div class="mt-4" *ngIf="selectedMissedWelfare">
      <div class="mt-2">
        <gtapp-map-leaflet
          *ngIf="selectedMissedWelfare?.gps"
          [latLon]="selectedMissedWelfare?.gps"
          mapId="missedWelfareItemMobile"
        ></gtapp-map-leaflet>
      </div>

      <div class="row mt-3 bottom-section" id="fixedBtn">
        <div class="px-3 pb-2">
          <button
            class="btn btn-dark d-block d-md-none w-100 btn-lg"
            (click)="selectedMissedWelfare = null"
          >
            Back
          </button>
        </div>
      </div>
    </div>
  </div>

  <div
    role="tabpanel"
    class="tab-pane"
    id="tab2"
    [ngClass]="selectedTab === tabTitles.tab2 ? 'active' : ''"
  >
    <div *ngIf="!largeView">
      <gtapp-card-list
        [mobileCardBackendData]="missedCheckpointData"
        [mobileCardDetails]="mobileDetailMissedCheckpoint"
        [otherParams]="otherParamsMissedCheckpoint"
        (searchColumn)="searchColumnMissedCheckpoint($event)"
        (sortColumn)="sortColumnMissedCheckpoint($event)"
        (paginationChange)="onChangePaginationMissedCheckpoint($event)"
      ></gtapp-card-list>
    </div>
    <div *ngIf="largeView">
      <gtapp-gt-table
        [desktopTableBackendData]="missedCheckpointData"
        [desktopTableDetails]="tableDetailMissedCheckpoint"
        [otherParams]="otherParamsMissedCheckpoint"
        (searchColumn)="searchColumnMissedCheckpoint($event)"
        (sortColumn)="sortColumnMissedCheckpoint($event)"
        (paginationChange)="onChangePaginationMissedCheckpoint($event)"
      >
      </gtapp-gt-table>
    </div>
  </div>
</div>
