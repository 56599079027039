import { Injectable } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';

@Injectable({
  providedIn: 'root',
})
export class PermissionsService {
  constructor() {}

  async checkCameraPermission(): Promise<boolean> {
    try {
      const permission = await navigator.permissions.query({
        name: 'camera' as PermissionName,
      });
      return permission.state === 'granted';
    } catch (error) {
      console.error('Error checking camera permission:', error);
      return false;
    }
  }

  async checkLocationPermission(): Promise<boolean> {
    try {
      const permission = await navigator.permissions.query({
        name: 'geolocation' as PermissionName,
      });
      if (permission.state === 'granted') {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.error('Error checking location permission:', error);
      return false;
    }
  }

  async checkNotificationPermission(): Promise<boolean> {
    try {
      const permission = await Notification.requestPermission();
      if (permission === 'granted') {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.error('Error checking notification permission:', error);
      return false;
    }
  }
}
