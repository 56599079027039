import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { isLargeScreen, isLargeScreenFun } from '../../../../global.variable';
import { AppService } from '../../../app.service';
import { ConfirmDialogComponent } from '../../../shared/components/confirm-dialog/confirm-dialog.component';
import { ModelDialogueService } from '../../../shared/components/modal-dialogue/model-dialogue.service';
import { DataCheckService } from '../../../shared/services/data-check.service';
import { LoadingSpinnerService } from '../../../shared/services/loading-spinner.service';
import { OrientationLockService } from '../../../shared/services/orientationlock.service';
import { ToasterService } from '../../../shared/services/toaster.service';
import { ProfileService } from '../../profile/profile.service';
import { PagesService } from '../pages.service';
import { CheckpointService } from './checkpoint.service';

@Component({
  selector: 'gtapp-checkpoints',
  templateUrl: './checkpoints.component.html',
  styleUrl: './checkpoints.component.scss',
})
export class CheckpointsComponent implements OnInit {
  viewType: string = 'tableView';
  checkPointList: any;

  totalRows: number = 0;
  previous: number = 0;
  rows: number = 5;
  pageNum: number = 1;
  totalPages: number = 0;

  globalFilter: any;
  showTypeAhead: boolean = true;
  showSearchBox: boolean = false;

  tabNames = {
    tab1: 'Checkpoints',
    tab2: 'QR Codes',
    tab3: 'Changes',
  };
  qrCodePage: boolean = false;
  // pagination variables for CP events

  tableStyle = {
    'overflow': 'auto',
    'max-height': '600px',
  };

  chekPointDetailsTableView: any = [
    {
      header: 'Site',
      name: 'site_name',
      sortKey: 'site__company_name',
      searchKey: 'site__company_name',
    },
    {
      header: 'Client',
      name: 'company',

      sortKey: 'company__company_name',
      searchKey: 'company__company_name',
    },
    {
      header: 'Checkpoint Name',
      name: 'name',
      sortKey: 'name',
      toolTipData: (row: any) => {
        return row?.scanned_count
          ? `${row?.scanned_count} scans till now.`
          : 'No scans yet.';
      },
    },
    {
      header: 'Location',

      name: 'partial_address',
      searchKey: 'partial_address',
    },
    {
      header: '',

      nestedValue: (row: any) => {
        return row?.qr_code ? 'QR' : '';
      },
      sortKey: 'qr_code',
    },
  ];

  clientId: any;
  siteId: any;

  mapLatLong: any;
  selectedCp: any;
  sortKey: any;
  searchParams: any;
  userData: any;

  validateQR: boolean = false;

  dialogRef: any;

  qrToBeValidatedCount: number = 0;
  selectedTab: string = this.tabNames.tab1;
  checkPointCardsDetails: any = {
    individualDetails: [
      {
        imageSrc: 'assets/images/qr-code-logo.avif',
        imageObj: true,
        nestedValue: (row: any) => {
          return Boolean(row?.qr_code);
        },
        imageStyle: { width: '26px', float: 'right' },
      },
      {
        name: 'name',
        cardRowStyle: { 'font-weight': 'bold' },
      },
      {
        nestedValue: (row: any) => {
          if (row?.site_name) {
            return `${row?.site_name} [${row?.company}]`;
          }
          return row?.company;
        },
        cardRowStyle: { 'font-size': 'small' },
      },
      {
        cardRowStyle: { 'font-size': 'small' },
        nestedValue: (row: any) => {
          return row?.address?.full_address;
        },
      },
    ],
  };
  checkpointDropDownList: any = [];
  otherParams: any = {};
  largeView: Boolean = isLargeScreen;
  isAdmin = this.dataCheckService.isUserAdmin();
  isDispatchUser = this.dataCheckService.isDispatchUser();
  isLargeScreenSubscription: Subscription = new Subscription();

  // infiniteDataList: any = [];
  constructor(
    private checkpointService: CheckpointService,
    private spinnerService: LoadingSpinnerService,
    private route: ActivatedRoute,
    private pageService: PagesService,
    private appService: AppService,
    private router: Router,
    private profileService: ProfileService,
    private dialogService: ModelDialogueService,
    private dataCheckService: DataCheckService,
    private toasterService: ToasterService,
    private orientationService: OrientationLockService
  ) {
    this.route.params.subscribe({
      next: (params: any) => {
        if (this.route.snapshot.fragment) {
          if (this.route.snapshot.fragment === 'validateQr') {
            this.validateQR = true;
          }
        }
      },
    });
  }
  ngOnDestroy() {
    this.isLargeScreenSubscription.unsubscribe();
  }

  ngOnInit(): void {
    this.rows = this.largeView ? 10 : 4;
    this.isLargeScreenSubscription =
      this.orientationService.isLargeScreen.subscribe(async (event: any) => {
        if (event) {
          this.largeView = await isLargeScreenFun();
          if (this.largeView) {
            this.viewType = cpListViewType?.desktopView || 'tableView';
          } else {
            this.viewType = cpListViewType?.mobileView || 'cardView';
          }
        }
      });
    this.userData = this.appService.getUserData();
    let cpListViewType = this.userData?.preferences?.cpListViewType;
    if (this.largeView) {
      this.viewType = cpListViewType?.desktopView || 'tableView';
    } else {
      this.viewType = cpListViewType?.mobileView || 'cardView';
    }
    this.clientId = this.route.snapshot.paramMap.get('clientId');
    this.siteId = this.route.snapshot.paramMap.get('siteId');

    this.getCheckPoints();
  }
  onCheckpointLookup(event: any) {
    if (
      event.target.value.length === 3 ||
      (event.target.value.length > 3 && this.checkpointDropDownList?.length)
    ) {
      this.checkpointService
        .getCheckpoints({ name: event.target.value })
        .subscribe((res: any) => {
          if (res['status'] == 'success') {
            this.checkpointDropDownList = res?.data;
          }
        });
    }
  }

  getCheckPoints() {
    let params: any = {};
    if (this.rows) {
      params['rows'] = this.rows;
    }
    if (this.previous) {
      params['previous'] = this.previous;
    }

    if (this.globalFilter) {
      params['name'] = this.globalFilter;
    }
    if (this.sortKey) {
      params['sort_key'] = this.sortKey;
    }
    if (this.searchParams) {
      Object.entries(this.searchParams).forEach(
        ([key, value]) => (params[key] = value)
      );
    }

    if (this.validateQR) {
      params['is_invalid'] = 1;
      this.viewType = this.largeView ? 'tableView' : 'cardView';
    }
    if (this.clientId) params['company_id'] = this.clientId;
    if (this.siteId) params['site_id'] = this.siteId;
    this.checkpointService.getCheckpoints(params).subscribe((response: any) => {
      if (response['status'] == 'success') {
        this.checkPointList = response?.data;
        // let newList = response?.data;
        // this.infiniteDataList = this.infiniteDataList.concat(newList);

        this.totalRows = response['total_size'];
        if (this.totalRows > 0) {
          this.appService.updateOnBoardingValue('checkpoint');
        }
        this.otherParams.paginationData = {
          totalRows: this.totalRows,
          previousRows: this.previous,
          rows: this.rows,
          pageNum: this.pageNum,
          totalPages: this.totalPages,
        };
        this.checkpointDropDownList = [];
        this.qrToBeValidatedCount = response?.validate_qr_count;
        this.totalPages = Math.ceil(this.totalRows / this.rows);
      } else {
        this.toasterService.setMessage({
          successMessage: '',
          errorMessage: response['message'],
        });
      }
    });
  }

  addCheckpoints() {
    this.router.navigate(['/add-checkpoint'], { relativeTo: this.route });
  }
  deleteCheckPoint(event: any) {
    let dialogMsg = `WARNING: This action cannot be reversed.`;
    const dialogRef = this.dialogService.open(ConfirmDialogComponent, {
      data: {
        title: `Delete Checkpoint ${event?.name}`,
        message: dialogMsg,
        alertDanger: true,
      },
    });
    dialogRef.afterClosed().subscribe((value: any) => {
      if (value === true) {
        this.checkpointService
          .deleteCheckpointById(event.id)
          .then((response: any) => {
            this.toasterService.setMessage({
              errorMessage: response['message'],
              successMessage: '',
            });
            let subscriberStats = JSON.parse(
              localStorage.getItem('subscriberStats') || '{}'
            );
            subscriberStats.checkpoint_count =
              (subscriberStats?.checkpoint_count || 0) - 1;
            localStorage.setItem(
              'subscriberStats',
              JSON.stringify(subscriberStats)
            );
            this.selectedCp = undefined;
            this.mapLatLong = undefined;
            this.getCheckPoints();
          });
      }
    });
  }

  redirectCompany(cp: any) {
    this.router.navigate(['/view-client', { cKey: String(cp?.company_id) }]);
  }
  onCheckpointRedirect(event: any) {
    this.router.navigate(['/view-checkpoints', { cpKey: String(event?.id) }]);
  }

  onCheckPointClick(event: any) {
    this.mapLatLong = {
      lat: event?.latitude,
      lon: event?.longitude,
    };
    if (event?.id === this.selectedCp?.id) {
      this.onCheckpointRedirect(event);
    }
    this.selectedCp = event;
  }

  onChangePagination(event: any) {
    this.previous = event?.paginationData?.previousRows;
    this.pageNum = event?.paginationData?.pageNum;
    this.rows = event?.paginationData?.rows;
    this.getCheckPoints();

    this.otherParams.paginationData = { ...event?.paginationData };
  }
  onMapChangePagination(event: any) {
    this.checkPointList = [];
    this.previous = event.previous;
    this.pageNum = event.pageNum;
    this.rows = event.pageSize;
    this.getCheckPoints();
  }
  clearPagination() {
    this.previous = 0;
    this.rows = this.largeView ? 10 : 4;
    this.pageNum = 1;
    this.totalPages = 0;
    this.totalRows = 0;
  }

  sortColumn(body: any) {
    this.clearPagination();
    this.sortKey = body?.sortList;
    this.searchParams = body?.searchParams || {};
    this.getCheckPoints();
  }
  searchColumn(event: any) {
    this.clearPagination();
    this.searchParams = event?.searchParams || {};
    this.getCheckPoints();
  }

  updatePreferences() {
    this.spinnerService.show();
    let body: any = {};
    let cpListViewType = this.userData?.preferences?.cpListViewType;

    if (this.largeView) {
      body = {
        desktopView: this.viewType,
        mobileView: cpListViewType?.mobileView || 'cardView',
      };
    } else {
      body = {
        mobileView: this.viewType,
        desktopView: cpListViewType?.desktopView || 'tableView',
      };
    }
    this.profileService
      .updateUser({ data: { cpListViewType: body } }, { save_preference: 1 })
      .then((response: any) => {
        this.spinnerService.hide();
      });

    let userData = this.appService.getUserData();
    if (userData?.preferences) {
      userData.preferences.cpListViewType = body;
      this.appService.setUserData(userData);
    }
  }
  changeTab(event: any) {
    this.qrCodePage = false;
    if (event === this.tabNames.tab2) {
      this.qrCodePage = true;
    }
    this.selectedTab = event;
  }

  validateCheckpoints() {
    this.validateQR = true;
    this.router.navigate(['/checkpoints']);
    this.getCheckPoints();
  }
  switchView() {
    if (this.largeView) {
      this.viewType == 'tableView'
        ? (this.viewType = 'mapView')
        : (this.viewType = 'tableView');
      this.updatePreferences();
    } else {
      this.viewType == 'cardView'
        ? (this.viewType = 'mapView')
        : (this.viewType = 'cardView');
      this.updatePreferences();
    }
  }
}
