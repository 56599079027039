import {
  Component,
  OnInit,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { startRegistration } from '@simplewebauthn/browser';
import { globalLicenseInfo, isLargeScreen } from '../../../global.variable';
import { AppService } from '../../app.service';
import { DataCheckService } from '../../shared/services/data-check.service';
import { PagesService } from '../pages/pages.service';
import { ProfileService } from './profile.service';

import { DeviceDetectorService } from 'ngx-device-detector';
import { AddEditLicenseDetailsComponent } from '../../shared/components/add-edit-license-details/add-edit-license-details.component';
import { ChangePasswordComponent } from '../../shared/components/change-password/change-password.component';
import { ConfirmDialogComponent } from '../../shared/components/confirm-dialog/confirm-dialog.component';
import { ModelDialogueService } from '../../shared/components/modal-dialogue/model-dialogue.service';
import { WebcamImageComponent } from '../../shared/components/web-cam-image/web-cam-image.component';
import { LoadingSpinnerService } from '../../shared/services/loading-spinner.service';
import { ToasterService } from '../../shared/services/toaster.service';
import { ClientsService } from '../pages/clients/clients.service';

@Component({
  selector: 'gtapp-profile',
  templateUrl: './profile.component.html',
  styleUrl: './profile.component.scss',
})
export class ProfileComponent implements OnInit {
  userDetails: any;
  dialogref: any;
  userData: any;
  profileForm: UntypedFormGroup = new UntypedFormGroup({
    email: new UntypedFormControl(''),
    first_name: new UntypedFormControl(''),
    last_name: new UntypedFormControl(''),
    mobile_number: new UntypedFormControl(''),
    time_zone: new UntypedFormControl(''),
  });
  message: string = '';

  userProfileUrl: any;
  imageUploaded: boolean = false;

  webcamImage: any;
  actualUploadFile: any;
  fileData: any = [];

  cardActionIcons = globalLicenseInfo?.icons;
  licenseDetails = {
    individualDetails: globalLicenseInfo?.details,
  };
  userLicenses: any = [];

  userGroups: any;
  currentTheme: any;

  isTrustedDevice: boolean = false;
  stateTimeZones: any;
  selectedTimeZone: any;
  muteNotification: number = 30;
  largeView: Boolean = isLargeScreen;
  constructor(
    private spinnerService: LoadingSpinnerService,
    private appService: AppService,
    private profileService: ProfileService,
    private dialogService: ModelDialogueService,
    private pageService: PagesService,
    private router: Router,
    private viewContainerRef: ViewContainerRef,
    private dataCheckService: DataCheckService,
    private clientService: ClientsService,
    private toasterService: ToasterService,
    private deviceService: DeviceDetectorService
  ) {
    // WHEN USER CLICK THE BACK BUTTON
    router.events.subscribe((event: any) => {
      if (event.navigationTrigger === 'popstate') {
        this.dialogref?.close();
      }
    });
  }

  async ngOnInit(): Promise<void> {
    this.profileService.getFidoDevices().subscribe(async (response: any) => {
      let currentDevice: any = this.deviceService.getDeviceInfo();

      let isDeviceRegistered = response?.data?.web_authn[0].some(
        (registeredDevice: any) => {
          return (
            registeredDevice.device.device === currentDevice.device &&
            registeredDevice.device.device_type === currentDevice.deviceType &&
            registeredDevice.device.device_os === currentDevice.os &&
            registeredDevice.device.browser === currentDevice.browser
          );
        }
      );
      if (isDeviceRegistered === true) {
        let userData = this.appService.getUserData();
        userData.checks.fido_credentials = true;
        userData.checks.fido_login = true;
        this.appService.setUserData(userData);
      }
    });

    this.userData = this.appService.getUserData();
    this.muteNotification = this.toasterService.getNotificationVolume();

    this.isTrustedDevice = this.dataCheckService.isTrustedDevice();
    if (this.userData?.profile?.profile_image_url) {
      this.userProfileUrl = this.userData?.profile?.profile_image_url;
      this.imageUploaded = true;
    } else {
      this.userProfileUrl = 'assets/images/img_avatar_blank.avif';
    }
    this.spinnerService.show();
    this.getProfileData();
    this.getTimeZoneDetails();
  }
  getTimeZoneDetails() {
    this.stateTimeZones = [];
    [
      'Australia/Darwin',
      'Australia/Sydney',
      'Australia/Brisbane',
      'Australia/Adelaide',
      'Australia/Hobart',
      'Australia/Melbourne',
      'Australia/Perth',
    ].forEach((timeZone: any) => {
      this.stateTimeZones.push(this.getTimezoneInfo(timeZone));
    });
  }

  getProfileData() {
    this.spinnerService.show();
    this.profileService.fetchUserProfile('api').subscribe((res) => {
      if (res['status'] === 'success') {
        this.formatProfileData(res['data']);
        this.spinnerService.hide();
      }
    });
  }
  formatProfileData(data: any) {
    this.userDetails = data;
    this.selectedTimeZone = data?.time_zone;
    if (data?.time_zone) {
      this.userDetails.timeZone = this.getTimezoneInfo(data?.time_zone);
    }

    this.userGroups = [
      ...this.userDetails?.user_group?.map((item: any) => {
        return item?.name;
      }),
    ];

    this.profileForm.reset(this.userDetails);
    this.profileForm.controls['time_zone'].setValue(data?.time_zone);
    this.userLicenses = data?.license;

    this.profileForm.get('email')?.disable();
    if (this.userDetails?.profile_image) {
      this.userProfileUrl = this.userDetails?.profile_image;
      this.imageUploaded = true;
      let userData = this.appService.getUserData();
      userData.profile.profile_image_url = this.userProfileUrl;

      this.appService.setUserData(userData);
    } else {
      this.userProfileUrl = 'assets/images/img_avatar_blank.avif';
    }
    this.pageService.miscSubjectParam.next({ updateProfileDetail: true });
  }

  editProfile(template: TemplateRef<any>) {
    const dialogref = this.dialogService.open(
      template,
      {
        data: {},
      },
      this.viewContainerRef
    );
    dialogref.afterClosed().subscribe((value) => {});
  }

  openPasswordForm() {
    const dialogRefCP = this.dialogService.open(ChangePasswordComponent, {
      data: '',
    });
    dialogRefCP.afterClosed().subscribe((value) => {});
  }
  addNewLicense() {
    const dialogRef = this.dialogService.open(AddEditLicenseDetailsComponent, {
      data: {},
    });
    dialogRef.afterClosed().subscribe((value: any) => {
      if (value !== 'close' && value !== undefined) {
        this.userLicenses = value;
      }
    });
  }

  onSubmitClicked(passwordForm: any) {
    if (passwordForm) {
      this.profileService.changePassword(passwordForm).subscribe((response) => {
        if (response['status'] == 'success') {
          this.toasterService.setMessage({
            successMessage: response['message'],
            errorMessage: '',
          });
        } else {
          this.toasterService.setMessage({
            successMessage: '',
            errorMessage: response['message'],
          });
        }
      });
    }
  }

  UpdateProfileDetails() {
    if (this.profileForm.valid) {
      this.profileForm.get('email')?.enable();
      let requestData = { data: this.profileForm.value };
      requestData['data']['time_zone'] = this.selectedTimeZone;
      if (!this.profileForm.value?.mobile_number) {
        this.profileForm.controls['mobile_number'].setValue(null);
      }
      this.profileService
        .updateProfileDetails(requestData, 'api')
        .then((response: any) => {
          if (response['status'] == 'success') {
            let userData = this.appService.getUserData();
            userData.profile = response['data'];
            this.appService.setUserData(userData);
            // window.location.reload();
            this.toasterService.setMessage({
              successMessage: response['message'],
              errorMessage: '',
            });

            this.formatProfileData(response['data']);
          } else {
            this.toasterService.setMessage({
              successMessage: '',
              errorMessage: response['message'],
            });
          }
        });
    }
  }
  deleteLicense(license: any) {
    let dialogMsg = 'Are you sure you want to delete this licence?';
    const dialogRef = this.dialogService.open(ConfirmDialogComponent, {
      data: {
        title: 'Delete Licence',
        message: dialogMsg,
      },
    });
    dialogRef.afterClosed().subscribe((value: any) => {
      if (value === true) {
        this.spinnerService.show();
        this.clientService
          .deleteLicense(license.id, 0)
          .then((response: any) => {
            if (response['status'] == 'success') {
              this.userLicenses = response['data'];
              this.spinnerService.hide();
              this.toasterService.setMessage({
                successMessage: response['message'],
                errorMessage: '',
              });
            } else {
              this.spinnerService.hide();
              this.toasterService.setMessage({
                successMessage: '',
                errorMessage: response['message'],
              });
            }
          });
      }
    });
  }
  checkEmailEnter(event: any) {
    let dialogMsg =
      'We will send an email to your new email address to validate your request.';
    const dialogRef = this.dialogService.open(ConfirmDialogComponent, {
      data: {
        title: 'Update Email',
        message: dialogMsg,
        body: true,
      },
    });
    dialogRef.afterClosed().subscribe((value) => {
      if (value.value === true && value.data != undefined) {
        this.spinnerService.show();
        let new_email = value.data;
        this.profileService
          .updateEmail({ new_email: new_email }, { get_link: 1 })
          .then((response: any) => {
            if (response['status'] == 'success') {
              this.spinnerService.hide();
              this.toasterService.setMessage({
                successMessage: response['message'],
                errorMessage: '',
              });
            } else {
              this.spinnerService.hide();
              this.toasterService.setMessage({
                successMessage: '',
                errorMessage: response['message'],
              });
            }
          });
      }
    });
  }
  openCamPopup() {
    this.dialogref = this.dialogService.open(WebcamImageComponent, {
      data: { showFrom: 'userProfilePage' },
    });

    this.dialogref.afterClosed().subscribe((value: any) => {
      if (value && value !== 'error') {
        this.webcamImage = value.webCamImage;
        this.actualUploadFile = value.actualFileUpload;
        this.onFileSubmit();
      }
    });
  }

  onFileSubmit() {
    this.spinnerService.show();
    let fileData: FormData = new FormData();
    fileData.append('file', this.actualUploadFile);

    this.appService
      .formDataApi(`profile/update_profile_image`, fileData, false, {})
      .then((response: any) => {
        if (response['status'] == 'success') {
          let userData = this.appService.getUserData();
          userData.profile.profile_image_url = response['url'];
          this.appService.setUserData(userData);

          this.dialogref.close();
          window.location.reload();
        } else {
          this.spinnerService.hide();
          this.toasterService.setMessage({
            errorMessage: response['message'],
            successMessage: '',
          });
        }
        this.actualUploadFile = null;
      });
  }
  removeImage() {
    let dialogMsg = `Are you sure to remove the profile picture?`;
    const dialogRef = this.dialogService.open(ConfirmDialogComponent, {
      data: {
        title: 'Remove Picture',
        message: dialogMsg,
      },
    });
    dialogRef.afterClosed().subscribe((value) => {
      if (value === true) {
        let params = { remove_profile_image: 1 };
        this.spinnerService.show();
        this.profileService
          .removeProfileImage(params)
          .subscribe((response: any) => {
            if (response['status'] == 'success') {
              let userData = this.appService.getUserData();
              userData.profile.profile_image_url = null;
              this.appService.setUserData(userData);
              this.toasterService.setMessage({
                // successMessage: response['message'],
                errorMessage: '',
              });
              window.location.reload();
            } else {
              this.toasterService.setMessage({
                errorMessage: response['message'],
                successMessage: '',
              });
            }
            this.spinnerService.hide();
          });
      }
    });
  }
  async fidoStartRegistration(responseBody: any) {
    try {
      let regResp = await startRegistration(JSON.parse(responseBody));
      if (regResp) {
        this.profileService
          .fidoRegister(regResp, { verify: 1 })
          .then((response: any) => {
            if (response['status'] == 'success') {
              let userData = this.appService.getUserData();
              userData.checks.fido_credentials = true;
              userData.checks.fido_login = true;
              this.appService.setUserData(userData);
              window.location.reload();
            } else {
              this.toasterService.setMessage({
                successMessage: '',
                errorMessage: response['message'],
              });
            }
          });
      }
    } catch (err: any) {
      console.error('Error during registration process:', err);
      if (err === 'The authenticator was previously registered') {
        let userData = this.appService.getUserData();
        userData.checks.fido_login = true;
        this.appService.setUserData(userData);
        window.location.reload();
      }
    }
  }

  fidoRegister() {
    this.profileService.fidoRegister().then((response: any) => {
      if (response['status'] == 'success') {
        this.fidoStartRegistration(response['data']);
      } else {
        this.toasterService.setMessage({
          successMessage: '',
          errorMessage: response['message'],
        });
      }
    });
  }

  toggleTheme() {
    let userData = this.appService.getUserData();
    this.currentTheme =
      userData?.preferences?.theme === 'default' ? 'dark' : 'default';
    userData.preferences.theme = this.currentTheme;
    this.appService.setUserData(userData);
    // this.themeService.changeTheme(this.currentTheme);
    this.profileService
      .updateUser(
        { data: { theme: this.currentTheme ? this.currentTheme : 'default' } },
        { save_preference: 1 }
      )
      .then((response: any) => {});
    window.location.reload();
  }
  mobileNumberCheck() {
    if (!this.profileForm.value?.mobile_number) {
      this.profileForm.controls['mobile_number'].setValue(null);
      this.profileForm.controls['mobile_number'].removeValidators([
        Validators.minLength(10),
        Validators.maxLength(10),
        Validators.pattern('^((\\+91-?)|0)?[0-9]{10}$'),
      ]);
    } else {
      this.profileForm.controls['mobile_number'].setValidators([
        Validators.minLength(10),
        Validators.maxLength(10),
        Validators.pattern('^((\\+91-?)|0)?[0-9]{10}$'),
      ]);
    }
  }

  getTimezoneInfo(timeZone: string): {
    timezone: string;
    localTime: string;
    gmtOffset: string;
  } {
    const now = new Date();
    const options: Intl.DateTimeFormatOptions = {
      timeZone,
      hour12: true,
      hour: '2-digit',
      minute: '2-digit',
    };

    const formatter = new Intl.DateTimeFormat('en-US', {
      timeZone: timeZone,
      hour12: false,
      hour: '2-digit',
      minute: '2-digit',
      timeZoneName: 'short',
    });

    let offsetString: any = formatter
      .formatToParts(now)
      .find((part) => part.type === 'timeZoneName')?.value;
    const utcIndex = offsetString.indexOf('GMT');
    offsetString = offsetString.slice(utcIndex + 3);
    // Check if minutes part is zero
    if (offsetString.length <= 3) {
      offsetString += ':00';
    }

    const info: any = {
      localTime: now.toLocaleTimeString('en-AU', options),
      gmtOffset: `(UTC ${offsetString})`,
      value: timeZone,
    };
    return info;
  }
  changeNotificationVolume() {
    this.spinnerService.show();
    this.profileService
      .updateUser(
        {
          data: {
            mute_notification: this.muteNotification,
          },
        },
        { save_preference: 1 }
      )
      .then((response: any) => {
        if (response?.status === 'success') {
          let userData = this.appService.getUserData();
          if (userData?.preferences) {
            userData.preferences.mute_notification = this.muteNotification;
            this.appService.setUserData(userData);
          }
          this.toasterService.setMessage({
            successMessage: 'Notification Sound Updated',
            errorMessage: '',
          });
          this.spinnerService.hide();
        } else {
          this.spinnerService.hide();
          this.toasterService.setMessage({
            successMessage: '',
            errorMessage: response['message'],
          });
        }
      });
  }
  onTimeZoneChange(event: any) {
    this.selectedTimeZone = event?.target.value;
  }
}
